export const SUCC = [
  { code: "000", nom: "VOYAGES MSH", telephone: "450-430-2151", courriel: "", ville: "Ste-Thérèse " },
  { code: "003", nom: "Regroupement Gaby|Msh affiliés", telephone: "450-430-2151", courriel: "", ville: "Ste-Thérèse | Mont St-Hilaire " },
  { code: "004", nom: "Voyages à Prix Fous", telephone: "1-844-PRIXFOU", courriel: "", ville: "Mont St-Hilaire " },
  { code: "005", nom: "Voyages Mont St-Hilaire", telephone: "450-464-6161", courriel: "", ville: "Mont St-Hilaire " },
  { code: "006", nom: "Mariage Sud", telephone: "1 844 535-3838", courriel: "info@mariagesud.com", ville: "Longueuil" },
  { code: "007", nom: "Direction voyage", telephone: "450-430-2151", courriel: "cynthia@gabymsh.ca", ville: "Ste-Thérèse " },
  { code: "008", nom: "Unikatours", telephone: "450-430-2151", courriel: "", ville: "" },
  { code: "009", nom: "Sensations Voyages", telephone: "514-242-2530", courriel: "infos@sensationsvoyages.ca ", ville: "Ste-Thérèse " },
  { code: "010", nom: "Tours Jules Verne", telephone: "1-877-850-7424", courriel: "", ville: "Ste-Thérèse " },
]

export const ORIGINE = [
  { label: `AAE - Annaba (El Mellah), Algérie`, value: `AAE - Annaba (El Mellah), Algérie` },
  { label: `AAH - Aachen, Allemagne`, value: `AAH - Aachen, Allemagne` },
  { label: `AAL - Aalborg, Danemark`, value: `AAL - Aalborg, Danemark` },
  { label: `AAP - Houston (Andrau Airpark), USA`, value: `AAP - Houston (Andrau Airpark), USA` },
  { label: `AAR - Aarhus (Tirstrup), Danemark`, value: `AAR - Aarhus (Tirstrup), Danemark` },
  { label: `ABJ - Abidjan (Port-Bouet), Côte d'Ivoire`, value: `ABJ - Abidjan (Port-Bouet), Côte d'Ivoire` },
  { label: `ABQ - Albuquerque International, USA`, value: `ABQ - Albuquerque International, USA` },
  { label: `ABS - Abu Simbel, Egypte`, value: `ABS - Abu Simbel, Egypte` },
  { label: `ABV - Abuja, Nigeria`, value: `ABV - Abuja, Nigeria` },
  { label: `ABZ - Aberdeen (Dyce), Ecosse`, value: `ABZ - Aberdeen (Dyce), Ecosse` },
  { label: `ACA - Acapulco (J. Alvarez), Mexique`, value: `ACA - Acapulco (J. Alvarez), Mexique` },
  { label: `ACC - Accra (Kotoka), Ghana`, value: `ACC - Accra (Kotoka), Ghana` },
  { label: `ACE - Arrecife (Lanzarote), Canaries`, value: `ACE - Arrecife (Lanzarote), Canaries` },
  { label: `ADB - Izmir (Adnan Menderes), Turquie`, value: `ADB - Izmir (Adnan Menderes), Turquie` },
  { label: `ADD - Addis Ababa (Bole), Ethiopie`, value: `ADD - Addis Ababa (Bole), Ethiopie` },
  { label: `ADE - Aden (International), Yémen`, value: `ADE - Aden (International), Yémen` },
  { label: `ADJ - Amman (Marka), Jordanie`, value: `ADJ - Amman (Marka), Jordanie` },
  { label: `ADL - Adelaide International, Australie`, value: `ADL - Adelaide International, Australie` },
  { label: `ADS - Dallas (Addison Airport), USA`, value: `ADS - Dallas (Addison Airport), USA` },
  { label: `ADZ - San Andres Island, Colombie`, value: `ADZ - San Andres Island, Colombie` },
  { label: `AEP - Buenos Aires (J. Newbery), Argentine`, value: `AEP - Buenos Aires (J. Newbery), Argentine` },
  { label: `AGA - Agadir (Al Massira), Maroc`, value: `AGA - Agadir (Al Massira), Maroc` },
  { label: `AGF - Agen, France`, value: `AGF - Agen, France` },
  { label: `AGP - Malaga, Espagne`, value: `AGP - Malaga, Espagne` },
  { label: `AGR - Agra, Inde`, value: `AGR - Agra, Inde` },
  { label: `AGS-Augusta , Georgie`, value: `AGS-Augusta , Georgie` },
  { label: `AHO - Alghero (Fertilia), Italie`, value: `AHO - Alghero (Fertilia), Italie` },
  { label: `AJA - Ajaccio (Campo Dell'Oro), France`, value: `AJA - Ajaccio (Campo Dell'Oro), France` },
  { label: `AJN - Anjouan, Comores`, value: `AJN - Anjouan, Comores` },
  { label: `AJY - Agades (Manu Dayak), Niger`, value: `AJY - Agades (Manu Dayak), Niger` },
  { label: `AKL - Auckland International, Nlle-Zélande`, value: `AKL - Auckland International, Nlle-Zélande` },
  { label: `ALA - Almaty (Alma Ata), Kazakhstan`, value: `ALA - Almaty (Alma Ata), Kazakhstan` },
  { label: `ALC - Alicante, Espagne`, value: `ALC - Alicante, Espagne` },
  { label: `ALG - Alger (Houari Boumediene), Algérie`, value: `ALG - Alger (Houari Boumediene), Algérie` },
  { label: `ALP - Alep, Syrie`, value: `ALP - Alep, Syrie` },
  { label: `ALY - Alexandrie, Egypte`, value: `ALY - Alexandrie, Egypte` },
  { label: `AMD - Ahmedabad, Inde`, value: `AMD - Ahmedabad, Inde` },
  { label: `AMI - Mataram (Selaparang), Indonésie`, value: `AMI - Mataram (Selaparang), Indonésie` },
  { label: `AMM - Amman (Queen Alia Inter.), Jordanie`, value: `AMM - Amman (Queen Alia Inter.), Jordanie` },
  { label: `AMS - Amsterdam (Schiphol), Pays-Bas`, value: `AMS - Amsterdam (Schiphol), Pays-Bas` },
  { label: `ANC - Anchorage Internat. Airport, Alaska`, value: `ANC - Anchorage Internat. Airport, Alaska` },
  { label: `ANE - Angers, France`, value: `ANE - Angers, France` },
  { label: `ANF - Antofagasta (Cerro Moreno), Chili`, value: `ANF - Antofagasta (Cerro Moreno), Chili` },
  { label: `ANG - Angoulême, France`, value: `ANG - Angoulême, France` },
  { label: `ANK - Ankara (Esenboga), Turquie`, value: `ANK - Ankara (Esenboga), Turquie` },
  { label: `ANR - Anvers (Deurne), Belgique`, value: `ANR - Anvers (Deurne), Belgique` },
  { label: `ANU - Antigua (Saint John's), Antigua`, value: `ANU - Antigua (Saint John's), Antigua` },
  { label: `APA - Denver (Centennial Airport), USA`, value: `APA - Denver (Centennial Airport), USA` },
  { label: `APW - Apia (Faleolo), Samoa`, value: `APW - Apia (Faleolo), Samoa` },
  { label: `AQJ - Aqaba, Jordanie`, value: `AQJ - Aqaba, Jordanie` },
  { label: `AQP - Arequipa (Rodriguez Ballon), Pérou`, value: `AQP - Arequipa (Rodriguez Ballon), Pérou` },
  { label: `ARK - Arusha, Tanzanie`, value: `ARK - Arusha, Tanzanie` },
  { label: `ARN - Stockholm (Arlanda), Suède`, value: `ARN - Stockholm (Arlanda), Suède` },
  { label: `ASB - Ashkhabad, Turkménistan`, value: `ASB - Ashkhabad, Turkménistan` },
  { label: `ASD - Andros International, Bahamas`, value: `ASD - Andros International, Bahamas` },
  { label: `ASM - Asmara (Yohannes IV), Erythrée`, value: `ASM - Asmara (Yohannes IV), Erythrée` },
  { label: `ASP - Alice Springs, Australie`, value: `ASP - Alice Springs, Australie` },
  { label: `ASR – Kayseri, Turquie`, value: `ASR – Kayseri, Turquie` },
  { label: `ASU - Asuncion (Stroessner), Paraguay`, value: `ASU - Asuncion (Stroessner), Paraguay` },
  { label: `ASW - Aswan, Egypte`, value: `ASW - Aswan, Egypte` },
  { label: `ATH - Athens (Hellinikon Internat.), Grèce`, value: `ATH - Athens (Hellinikon Internat.), Grèce` },
  { label: `ATL - Atlanta International Airport, USA`, value: `ATL - Atlanta International Airport, USA` },
  { label: `ATQ - Amritsar, Inde`, value: `ATQ - Amritsar, Inde` },
  { label: `ATR - Atar, Mauritanie`, value: `ATR - Atar, Mauritanie` },
  { label: `AUA - Oranjestad (Queen Beatrix), Aruba`, value: `AUA - Oranjestad (Queen Beatrix), Aruba` },
  { label: `AUF - Auxerre, France`, value: `AUF - Auxerre, France` },
  { label: `AUH - Abu Dhabi (Nadia International), EAU`, value: `AUH - Abu Dhabi (Nadia International), EAU` },
  { label: `AUR - Aurillac, France`, value: `AUR - Aurillac, France` },
  { label: `AUS-Auston,Texas`, value: `AUS-Auston,Texas` },
  { label: `AVN - Avignon (Caumont), France`, value: `AVN - Avignon (Caumont), France` },
  { label: `AWG - Washington Municipal Airport, USA`, value: `AWG - Washington Municipal Airport, USA` },
  { label: `AXA - Anguilla (The Valley), Anguilla`, value: `AXA - Anguilla (The Valley), Anguilla` },
  { label: `AXH - Houston-Southwest Airport, USA`, value: `AXH - Houston-Southwest Airport, USA` },
  { label: `AYQ - Ayers Rock, Australie`, value: `AYQ - Ayers Rock, Australie` },
  { label: `AYT - Antalya, Turquie`, value: `AYT - Antalya, Turquie` },
  { label: `AZI - Abu Dhabi (Bettina), EAU`, value: `AZI - Abu Dhabi (Bettina), EAU` },
  { label: `AZS -  Samana, République Dominicaine`, value: `AZS -  Samana, République Dominicaine` },
  { label: `BAH - Bahrain Muharraq Internat., Bahrain`, value: `BAH - Bahrain Muharraq Internat., Bahrain` },
  { label: `BAK - Baku Bina Internat. Airport, Azerbaijan`, value: `BAK - Baku Bina Internat. Airport, Azerbaijan` },
  { label: `BBK - Kasane, Botswana`, value: `BBK - Kasane, Botswana` },
  { label: `BBQ - Barbuda (Codrington), Antigua et Barbuda`, value: `BBQ - Barbuda (Codrington), Antigua et Barbuda` },
  { label: `BBR - Basse-Terre, Guadeloupe`, value: `BBR - Basse-Terre, Guadeloupe` },
  { label: `BBU - Bucarest (Baneasa Airport), Roumanie`, value: `BBU - Bucarest (Baneasa Airport), Roumanie` },
  { label: `BBZ - Zambezi, Zambie`, value: `BBZ - Zambezi, Zambie` },
  { label: `BCN - Barcelone Airport, Espagne`, value: `BCN - Barcelone Airport, Espagne` },
  { label: `BDA - Bermuda (Kindley Field), Bermudes`, value: `BDA - Bermuda (Kindley Field), Bermudes` },
  { label: `BDS - Brindisi (Casale), Italie`, value: `BDS - Brindisi (Casale), Italie` },
  { label: `BEG - Belgrade (N. Tesla Internat.), Serbie`, value: `BEG - Belgrade (N. Tesla Internat.), Serbie` },
  { label: `BEL - Belem (Vale de Caes), Brésil`, value: `BEL - Belem (Vale de Caes), Brésil` },
  { label: `BEN - Benghazi (Benina), Libye`, value: `BEN - Benghazi (Benina), Libye` },
  { label: `BER - Berlin (Metropolitan Airport), Allemagne`, value: `BER - Berlin (Metropolitan Airport), Allemagne` },
  { label: `BES - Brest (Guipavas), France`, value: `BES - Brest (Guipavas), France` },
  { label: `BEW - Beira, Mozambique`, value: `BEW - Beira, Mozambique` },
  { label: `BEY - Beyrouth International Airport, Liban`, value: `BEY - Beyrouth International Airport, Liban` },
  { label: `BFI - Seattle (Boeing Field Internat.), USA`, value: `BFI - Seattle (Boeing Field Internat.), USA` },
  { label: `BFS - Belfast (Internat. Airport), Irlande du Nord`, value: `BFS - Belfast (Internat. Airport), Irlande du Nord` },
  { label: `BGF - Bangui (M'Poko), Centrafrique`, value: `BGF - Bangui (M'Poko), Centrafrique` },
  { label: `BGI - Bridgetown (G. Adams Int.), Barbade`, value: `BGI - Bridgetown (G. Adams Int.), Barbade` },
  { label: `BGO - Bergen (Flesland), Norvège`, value: `BGO - Bergen (Flesland), Norvège` },
  { label: `BGW - Bagdad (Metropolitan Area), Irak`, value: `BGW - Bagdad (Metropolitan Area), Irak` },
  { label: `BGY - Bergamo, Italie`, value: `BGY - Bergamo, Italie` },
  { label: `BGZ - Braga, Portugal`, value: `BGZ - Braga, Portugal` },
  { label: `BHD - Belfast City Airport, Irlande du Nord`, value: `BHD - Belfast City Airport, Irlande du Nord` },
  { label: `BHK - Bukhara Airport, Ouzbékistan`, value: `BHK - Bukhara Airport, Ouzbékistan` },
  { label: `BHX - Birmingham Internat. Airport, Angleterre`, value: `BHX - Birmingham Internat. Airport, Angleterre` },
  { label: `BHZ - Belo Horizonte (Confins), Brésil`, value: `BHZ - Belo Horizonte (Confins), Brésil` },
  { label: `BIA - Bastia (Poretta), France`, value: `BIA - Bastia (Poretta), France` },
  { label: `BIO - Bilbao, Espagne`, value: `BIO - Bilbao, Espagne` },
  { label: `BIQ - Biarritz, France`, value: `BIQ - Biarritz, France` },
  { label: `BJA - Bejaia (Soummam), Algérie`, value: `BJA - Bejaia (Soummam), Algérie` },
  { label: `BJC - Denver/Broomfield (Jeffco Airport), USA`, value: `BJC - Denver/Broomfield (Jeffco Airport), USA` },
  { label: `BJL - Banjul (Yundum), Gambie`, value: `BJL - Banjul (Yundum), Gambie` },
  { label: `BJM - Bujumbura (International), Burundi`, value: `BJM - Bujumbura (International), Burundi` },
  { label: `BJS - Beijing Metropolitan Airport, Chine`, value: `BJS - Beijing Metropolitan Airport, Chine` },
  { label: `BJY - Belgrade, Serbie`, value: `BJY - Belgrade, Serbie` },
  { label: `BKA - Moscow (Bykovo), Russie`, value: `BKA - Moscow (Bykovo), Russie` },
  { label: `BKI - Kota Kinabalu, Malaisie`, value: `BKI - Kota Kinabalu, Malaisie` },
  { label: `BKK - Bangkok (Don Muang Internat.), Thailande`, value: `BKK - Bangkok (Don Muang Internat.), Thailande` },
  { label: `BKL - Cleveland (Burke Lakefront Airport), USA`, value: `BKL - Cleveland (Burke Lakefront Airport), USA` },
  { label: `BKO - Bamako (Senou), Mali`, value: `BKO - Bamako (Senou), Mali` },
  { label: `BLA - Barcelona (J. A. Anzoategui), Venezuela`, value: `BLA - Barcelona (J. A. Anzoategui), Venezuela` },
  { label: `BLL - Billund, Danemark`, value: `BLL - Billund, Danemark` },
  { label: `BLQ - Bologna (Giuseppe Marconi), Italie`, value: `BLQ - Bologna (Giuseppe Marconi), Italie` },
  { label: `BLZ - Blantyre (Chilika), Malawi`, value: `BLZ - Blantyre (Chilika), Malawi` },
  { label: `BMA - Stockholm (Bromma), Suède`, value: `BMA - Stockholm (Bromma), Suède` },
  { label: `BNE - Brisbane (International), Australie`, value: `BNE - Brisbane (International), Australie` },
  { label: `BNJ - Bonn (Wahn), Allemagne`, value: `BNJ - Bonn (Wahn), Allemagne` },
  { label: `BOB - Bora Bora, Polynésie Française`, value: `BOB - Bora Bora, Polynésie Française` },
  { label: `BOD - Bordeaux (Merignac), France`, value: `BOD - Bordeaux (Merignac), France` },
  { label: `BOG - Bogota (Eldorado), Colombie`, value: `BOG - Bogota (Eldorado), Colombie` },
  { label: `BOH - Bournemouth, Angleterre`, value: `BOH - Bournemouth, Angleterre` },
  { label: `BOJ - Burgas, Bulgarie`, value: `BOJ - Burgas, Bulgarie` },
  { label: `BOM - Bombay (Ch. Shivaji Int.), Inde`, value: `BOM - Bombay (Ch. Shivaji Int.), Inde` },
  { label: `BON - Bonaire (Flamingo), Antilles Néerl.`, value: `BON - Bonaire (Flamingo), Antilles Néerl.` },
  { label: `BOO - Bodo, Norvège`, value: `BOO - Bodo, Norvège` },
  { label: `BOS - Boston (Logan Internat.), USA`, value: `BOS - Boston (Logan Internat.), USA` },
  { label: `BQE - Bubaque, Guinée Bissau`, value: `BQE - Bubaque, Guinée Bissau` },
  { label: `BQU - Bequia, St. Vincent et Grenadines`, value: `BQU - Bequia, St. Vincent et Grenadines` },
  { label: `BRC - San Carlos de Bariloche, Argentine`, value: `BRC - San Carlos de Bariloche, Argentine` },
  { label: `BRE - Bremen (Nieuenland), Allemagne`, value: `BRE - Bremen (Nieuenland), Allemagne` },
  { label: `BRI - Bari (Palese Macchie), Italie`, value: `BRI - Bari (Palese Macchie), Italie` },
  { label: `BRN - Berne, Suisse`, value: `BRN - Berne, Suisse` },
  { label: `BRO - Brownsville, TX`, value: `BRO - Brownsville, TX` },
  { label: `BRQ - Brno, Rép. Tchèque`, value: `BRQ - Brno, Rép. Tchèque` },
  { label: `BRS - Bristol (Lulsgate), Angleterre`, value: `BRS - Bristol (Lulsgate), Angleterre` },
  { label: `BRU - Bruxelles (National/Zaventem), Belgique`, value: `BRU - Bruxelles (National/Zaventem), Belgique` },
  { label: `BRX - Barahona, République Dominicaine`, value: `BRX - Barahona, République Dominicaine` },
  { label: `BSB - Brasilia Internacional, Brésil`, value: `BSB - Brasilia Internacional, Brésil` },
  { label: `BSG - Bata, Guinée Equatoriale`, value: `BSG - Bata, Guinée Equatoriale` },
  { label: `BSK - Biskra, Algérie`, value: `BSK - Biskra, Algérie` },
  { label: `BSL - Bâle/Mulhouse (Euro Airport), Suisse`, value: `BSL - Bâle/Mulhouse (Euro Airport), Suisse` },
  { label: `BTS - Bratislava (Ivanka Airport), Slovaquie`, value: `BTS - Bratislava (Ivanka Airport), Slovaquie` },
  { label: `BUD - Budapest (Ferihegyi), Hongrie`, value: `BUD - Budapest (Ferihegyi), Hongrie` },
  { label: `BUE - Buenos Aires, Argentine`, value: `BUE - Buenos Aires, Argentine` },
  { label: `BUH - Bucarest, Roumanie`, value: `BUH - Bucarest, Roumanie` },
  { label: `BVA - Beauvais (Tille), France`, value: `BVA - Beauvais (Tille), France` },
  { label: `BVB - Boa Vista, Brésil`, value: `BVB - Boa Vista, Brésil` },
  { label: `BVC - Boavista, Cap Vert`, value: `BVC - Boavista, Cap Vert` },
  { label: `BVE - Brive, France`, value: `BVE - Brive, France` },
  { label: `BWI - Baltimore/Washington Internat., USA`, value: `BWI - Baltimore/Washington Internat., USA` },
  { label: `BWN - Bandar Seri Begwan Internat., Brunei`, value: `BWN - Bandar Seri Begwan Internat., Brunei` },
  { label: `BXO - Bissau (Bissallanca), Guinée Bissau`, value: `BXO - Bissau (Bissallanca), Guinée Bissau` },
  { label: `BZE - Belize International Airport, Belize`, value: `BZE - Belize International Airport, Belize` },
  { label: `BZR - Béziers, France`, value: `BZR - Béziers, France` },
  { label: `CAG - Cagliari (Elmas), Italie`, value: `CAG - Cagliari (Elmas), Italie` },
  { label: `CAI - Le Caire International Airport, Egypte`, value: `CAI - Le Caire International Airport, Egypte` },
  { label: `CAN - Guangzhou (Baiyun), Chine`, value: `CAN - Guangzhou (Baiyun), Chine` },
  { label: `CAP - Cap Haitien, Haiti`, value: `CAP - Cap Haitien, Haiti` },
  { label: `CAS - Casablanca (Metropolitan), Maroc`, value: `CAS - Casablanca (Metropolitan), Maroc` },
  { label: `CAY - Cayenne (Rochambeau), Guyane`, value: `CAY - Cayenne (Rochambeau), Guyane` },
  { label: `CBR - Canberra, Australie`, value: `CBR - Canberra, Australie` },
  { label: `CCC - Cayo Coco, Cuba`, value: `CCC - Cayo Coco, Cuba` },
  { label: `CCE - Saint Martin (Grand Case), Guadeloupe`, value: `CCE - Saint Martin (Grand Case), Guadeloupe` },
  { label: `CCF - Carcassonne, France`, value: `CCF - Carcassonne, France` },
  { label: `CCS - Caracas (Aeropuerto Intern.), Venezuela`, value: `CCS - Caracas (Aeropuerto Intern.), Venezuela` },
  { label: `CCU - Calcutta (Dum Dum Internat.), Inde`, value: `CCU - Calcutta (Dum Dum Internat.), Inde` },
  { label: `CDG - Paris (Charles de Gaulle), France`, value: `CDG - Paris (Charles de Gaulle), France` },
  { label: `CEB - Cebu, Philippines`, value: `CEB - Cebu, Philippines` },
  { label: `CEI - Chiang Rai Internat. Airport, Thailande`, value: `CEI - Chiang Rai Internat. Airport, Thailande` },
  { label: `CEQ - Cannes (Mandelieu), France`, value: `CEQ - Cannes (Mandelieu), France` },
  { label: `CER - Cherbourg (Maupertus), France`, value: `CER - Cherbourg (Maupertus), France` },
  { label: `CFE - Clermont-Ferrand (Aulnat), France`, value: `CFE - Clermont-Ferrand (Aulnat), France` },
  { label: `CFG - Cienfuegos, Cuba`, value: `CFG - Cienfuegos, Cuba` },
  { label: `CFR - Caen (Carpiquet), France`, value: `CFR - Caen (Carpiquet), France` },
  { label: `CGH - Sao Paulo (Congonhas), Brésil`, value: `CGH - Sao Paulo (Congonhas), Brésil` },
  { label: `CGK - Jakarta (Soekarno-Hatta Int'l), Indonésie`, value: `CGK - Jakarta (Soekarno-Hatta Int'l), Indonésie` },
  { label: `CGN - Cologne/Bonn, Allemagne`, value: `CGN - Cologne/Bonn, Allemagne` },
  { label: `CGO - Zhengzhou, Chine`, value: `CGO - Zhengzhou, Chine` },
  { label: `CGP - Chittagong (Patenga), Bangladesh`, value: `CGP - Chittagong (Patenga), Bangladesh` },
  { label: `CGT - Chinguitti, Mauritanie`, value: `CGT - Chinguitti, Mauritanie` },
  { label: `CGX - Chicago (Meigs Airport), USA`, value: `CGX - Chicago (Meigs Airport), USA` },
  { label: `CHC - Christchurch Int'l Airport, Nlle-Zélande`, value: `CHC - Christchurch Int'l Airport, Nlle-Zélande` },
  { label: `CHH - Chachapoyas, Pérou`, value: `CHH - Chachapoyas, Pérou` },
  { label: `CHI - Chicago (Metropolitan Area), USA`, value: `CHI - Chicago (Metropolitan Area), USA` },
  { label: `CHR - Châteauroux (Deols), France`, value: `CHR - Châteauroux (Deols), France` },
  { label: `CIA - Rome (Ciampino), Italie`, value: `CIA - Rome (Ciampino), Italie` },
  { label: `CJB -  Inde (Coimbatore)`, value: `CJB -  Inde (Coimbatore)` },
  { label: `CLE - Cleveland`, value: `CLE - Cleveland` },
  { label: `CLT - Charlotte,  Caroline du Nord`, value: `CLT - Charlotte,  Caroline du Nord` },
  { label: `CLY - Calvi (Sainte Catherine), France`, value: `CLY - Calvi (Sainte Catherine), France` },
  { label: `CMB - Colombo (Katunayake), Sri Lanka`, value: `CMB - Colombo (Katunayake), Sri Lanka` },
  { label: `CMF - Chambery (Aix-Les-Bains), France`, value: `CMF - Chambery (Aix-Les-Bains), France` },
  { label: `CMN - Casablanca (Mohamed V), Maroc`, value: `CMN - Casablanca (Mohamed V), Maroc` },
  { label: `CMR - Colmar, France`, value: `CMR - Colmar, France` },
  { label: `CMW - Camaguey Cuba`, value: `CMW - Camaguey Cuba` },
  { label: `CND - Constanza (M. Kogainiceanu), Roumanie`, value: `CND - Constanza (M. Kogainiceanu), Roumanie` },
  { label: `CNF - Belo Horizonte, MG, Brésil`, value: `CNF - Belo Horizonte, MG, Brésil` },
  { label: `CNG - Cognac, France`, value: `CNG - Cognac, France` },
  { label: `CNS - Cairns International, Australie`, value: `CNS - Cairns International, Australie` },
  { label: `CNX - Chiang Mai International, Thaïlande`, value: `CNX - Chiang Mai International, Thaïlande` },
  { label: `CLJ - Cluj-Napoca, Roumanie`, value: `CLJ - Cluj-Napoca, Roumanie` },
  { label: `COO - Cotonou (Cadjehoun), Benin`, value: `COO - Cotonou (Cadjehoun), Benin` },
  { label: `CPD - Coober Pedy, Australie`, value: `CPD - Coober Pedy, Australie` },
  { label: `CPE - Campeche (Alberto Ongay), Mexique`, value: `CPE - Campeche (Alberto Ongay), Mexique` },
  { label: `CPH - Copenhagen (Kastrup), Danemark`, value: `CPH - Copenhagen (Kastrup), Danemark` },
  { label: `CPT - Cape Town (D.F. Malan), Afrique du Sud`, value: `CPT - Cape Town (D.F. Malan), Afrique du Sud` },
  { label: `CRK - Luzon (Clark Field), Philippines`, value: `CRK - Luzon (Clark Field), Philippines` },
  { label: `CRL - Charleroi (Gosselies), Belgique`, value: `CRL - Charleroi (Gosselies), Belgique` },
  { label: `CSK - Cap Skirring, Sénégal`, value: `CSK - Cap Skirring, Sénégal` },
  { label: `CTA - Catane (Fontanarossa), Italie`, value: `CTA - Catane (Fontanarossa), Italie` },
  { label: `CTG - Cartagena (Rafael Nunez), Colombie`, value: `CTG - Cartagena (Rafael Nunez), Colombie` },
  { label: `CTU - Chengdu, Chine`, value: `CTU - Chengdu, Chine` },
  { label: `CFU - Corfu  Grèce`, value: `CFU - Corfu  Grèce` },
  { label: `CUE - Cuenca, Equateur`, value: `CUE - Cuenca, Equateur` },
  { label: `CUK - Caye Caulker, Belize`, value: `CUK - Caye Caulker, Belize` },
  { label: `CUN - Cancun International, Mexique`, value: `CUN - Cancun International, Mexique` },
  { label: `CUR - Curacao (Hato), Antilles Néerlandaises`, value: `CUR - Curacao (Hato), Antilles Néerlandaises` },
  { label: `CUZ - Cuzco, Pérou`, value: `CUZ - Cuzco, Pérou` },
  { label: `CWL - Cardiff (Rhoose), Pays de Galles`, value: `CWL - Cardiff (Rhoose), Pays de Galles` },
  { label: `CXH - Vancouver (Coal Harbour), Canada`, value: `CXH - Vancouver (Coal Harbour), Canada` },
  { label: `CYO- Cayo Largo, Cuba`, value: `CYO- Cayo Largo, Cuba` },
  { label: `CZL - Constantine (Ain El Bey), Algérie`, value: `CZL - Constantine (Ain El Bey), Algérie` },
  { label: `CZM - Cozumel, Mexique`, value: `CZM - Cozumel, Mexique` },
  { label: `CZW - Czestochowa, Pologne`, value: `CZW - Czestochowa, Pologne` },
  { label: `CZX - Changzhou, Chine`, value: `CZX - Changzhou, Chine` },
  { label: `DAB - DAYTONA BEACH`, value: `DAB - DAYTONA BEACH` },
  { label: `DAC - Dhaka (Zia Internat.), Bangladesh`, value: `DAC - Dhaka (Zia Internat.), Bangladesh` },
  { label: `DAD - Da Nang, Viêtnam`, value: `DAD - Da Nang, Viêtnam` },
  { label: `DAL - Dallas Love Field, USA`, value: `DAL - Dallas Love Field, USA` },
  { label: `DAM - Damas, Syrie`, value: `DAM - Damas, Syrie` },
  { label: `DAR - Dar es Salaam, Tanzanie`, value: `DAR - Dar es Salaam, Tanzanie` },
  { label: `DBV - Dubrovnik, Croatie`, value: `DBV - Dubrovnik, Croatie` },
  { label: `DBX -Dubai`, value: `DBX -Dubai` },
  { label: `DCA - Washington National Airport, USA`, value: `DCA - Washington National Airport, USA` },
  { label: `DCM - Castres, France`, value: `DCM - Castres, France` },
  { label: `DCY - Washington (Daviess County), USA`, value: `DCY - Washington (Daviess County), USA` },
  { label: `DDM - Dodoma, Tanzanie`, value: `DDM - Dodoma, Tanzanie` },
  { label: `DEL - Delhi (Indira Gandhi Internat), Inde`, value: `DEL - Delhi (Indira Gandhi Internat), Inde` },
  { label: `DEN - Denver (Stapleton Internat.), USA`, value: `DEN - Denver (Stapleton Internat.), USA` },
  { label: `DET - Detroit City Airport, USA`, value: `DET - Detroit City Airport, USA` },
  { label: `DFA - Abu Dhabi (Al Dhafra Airport), EAU`, value: `DFA - Abu Dhabi (Al Dhafra Airport), EAU` },
  { label: `DFW - Dallas/Fort Worth Internat., USA`, value: `DFW - Dallas/Fort Worth Internat., USA` },
  { label: `DHM - Dharamsala, Inde`, value: `DHM - Dharamsala, Inde` },
  { label: `DIJ - Dijon (Longvic), France`, value: `DIJ - Dijon (Longvic), France` },
  { label: `DIL - Dili (Komoro), East Timor`, value: `DIL - Dili (Komoro), East Timor` },
  { label: `DIR - Dire Dawa (A.T.D. Yilma), Ethiopie`, value: `DIR - Dire Dawa (A.T.D. Yilma), Ethiopie` },
  { label: `DJE - Djerba/Zarzis, Tunisie`, value: `DJE - Djerba/Zarzis, Tunisie` },
  { label: `DJG - Djanet (Tiska), Algérie`, value: `DJG - Djanet (Tiska), Algérie` },
  { label: `DKR - Dakar (Yoff), Sénégal`, value: `DKR - Dakar (Yoff), Sénégal` },
  { label: `DLA - Douala, Cameroun`, value: `DLA - Douala, Cameroun` },
  { label: `DLM - Dalaman, Turquie`, value: `DLM - Dalaman, Turquie` },
  { label: `DME - Moscow (Domodemovo), Russie`, value: `DME - Moscow (Domodemovo), Russie` },
  { label: `DNK - Dnipropetrovsk, Ukraine`, value: `DNK - Dnipropetrovsk, Ukraine` },
  { label: `DNR - Dinard (St. Malo), France`, value: `DNR - Dinard (St. Malo), France` },
  { label: `DOH - Doha, Qatar`, value: `DOH - Doha, Qatar` },
  { label: `DOL - Deauville (Saint Gatien), France`, value: `DOL - Deauville (Saint Gatien), France` },
  { label: `DPS - Denpasar (Ngurah Rai), Indonésie`, value: `DPS - Denpasar (Ngurah Rai), Indonésie` },
  { label: `DRS - Dresde, Allemagne`, value: `DRS - Dresde, Allemagne` },
  { label: `DRW - Darwin, Australie`, value: `DRW - Darwin, Australie` },
  { label: `DSA - Doncaster/Sheffield, Angleterre`, value: `DSA - Doncaster/Sheffield, Angleterre` },
  { label: `DSD - La Desirade, Guadeloupe`, value: `DSD - La Desirade, Guadeloupe` },
  { label: `DTH - Death Valley, USA`, value: `DTH - Death Valley, USA` },
  { label: `DTM - Dortmund, Allemagne`, value: `DTM - Dortmund, Allemagne` },
  { label: `DTT - Detroit (Metropolitan Area), USA`, value: `DTT - Detroit (Metropolitan Area), USA` },
  { label: `DTW - Detroit (Wayne County Airport), USA`, value: `DTW - Detroit (Wayne County Airport), USA` },
  { label: `DUB - Dublin, Irlande`, value: `DUB - Dublin, Irlande` },
  { label: `DUR - Durban (L. Botha), Afrique du Sud`, value: `DUR - Durban (L. Botha), Afrique du Sud` },
  { label: `DUS - Dusseldorf (Rhein-Ruhr), Allemagne`, value: `DUS - Dusseldorf (Rhein-Ruhr), Allemagne` },
  { label: `DWC-Al Maktoum International, Dubai`, value: `DWC-Al Maktoum International, Dubai` },
  { label: `DWH - Houston (D. W. Hooks), USA`, value: `DWH - Houston (D. W. Hooks), USA` },
  { label: `DWS - Orlando (Lake Buena Vista), USA`, value: `DWS - Orlando (Lake Buena Vista), USA` },
  { label: `DXB - Dubai International Airport, EAU`, value: `DXB - Dubai International Airport, EAU` },
  { label: `DYU - Dushanbe, Tajikistan`, value: `DYU - Dushanbe, Tajikistan` },
  { label: `DZA - Dzaoudzi (Pamandzi), Mayotte`, value: `DZA - Dzaoudzi (Pamandzi), Mayotte` },
  { label: `EAP - Bâle/Mulhouse, Suisse/France`, value: `EAP - Bâle/Mulhouse, Suisse/France` },
  { label: `EAS - San Sebastian, Espagne`, value: `EAS - San Sebastian, Espagne` },
  { label: `EBB - Kampala (Entebbe), Ouganda`, value: `EBB - Kampala (Entebbe), Ouganda` },
  { label: `EBU - Saint-Etienne (Boutheon), France`, value: `EBU - Saint-Etienne (Boutheon), France` },
  { label: `EDI - Edinburgh, Ecosse`, value: `EDI - Edinburgh, Ecosse` },
  { label: `EFD - Houston (Ellington Field), USA`, value: `EFD - Houston (Ellington Field), USA` },
  { label: `EGC - Bergerac, France`, value: `EGC - Bergerac, France` },
  { label: `EIN - Eindhoven, Pays-Bas`, value: `EIN - Eindhoven, Pays-Bas` },
  { label: `ELS - East London, Afrique du Sud`, value: `ELS - East London, Afrique du Sud` },
  { label: `EMA - East Midlands (Derby), Angleterre`, value: `EMA - East Midlands (Derby), Angleterre` },
  { label: `ENC - Nancy (Essey), France`, value: `ENC - Nancy (Essey), France` },
  { label: `ERS - Windhoek (Eros), Namibie`, value: `ERS - Windhoek (Eros), Namibie` },
  { label: `ESB - Ankara (Esenboga), Turquie`, value: `ESB - Ankara (Esenboga), Turquie` },
  { label: `ETZ - Metz/Nancy (Lorraine), France`, value: `ETZ - Metz/Nancy (Lorraine), France` },
  { label: `EVN - Yerevan (Zvartnots), Armenie`, value: `EVN - Yerevan (Zvartnots), Armenie` },
  { label: `EWR-Newark`, value: `EWR-Newark` },
  { label: `EXT - Exeter, Angleterre`, value: `EXT - Exeter, Angleterre` },
  { label: `EZE - Buenos Aires (Ezeiza), Argentine`, value: `EZE - Buenos Aires (Ezeiza), Argentine` },
  { label: `FAI- Fairbanks,Alaska`, value: `FAI- Fairbanks,Alaska` },
  { label: `FAO - Faro, Portugal`, value: `FAO - Faro, Portugal` },
  { label: `FBU - Oslo (Fornebu), Norvège`, value: `FBU - Oslo (Fornebu), Norvège` },
  { label: `FCO - Rome (L. de Vinci/Fiumicino), Italie`, value: `FCO - Rome (L. de Vinci/Fiumicino), Italie` },
  { label: `FDF - Fort-de-France (Le Lamentin), Martinique`, value: `FDF - Fort-de-France (Le Lamentin), Martinique` },
  { label: `FDH - Friedrichshafen (Lowenthal), Allemagne`, value: `FDH - Friedrichshafen (Lowenthal), Allemagne` },
  { label: `FEZ - Fès (Saiss), Maroc`, value: `FEZ - Fès (Saiss), Maroc` },
  { label: `FGI - Apia (Fabali Si), Samoa`, value: `FGI - Apia (Fabali Si), Samoa` },
  { label: `FIH - Kinshasa (Ndjili), RDC`, value: `FIH - Kinshasa (Ndjili), RDC` },
  { label: `FJR - Fujairah International Airport, EAU`, value: `FJR - Fujairah International Airport, EAU` },
  { label: `FKB - Karlsruhe/Baden, Allemagne`, value: `FKB - Karlsruhe/Baden, Allemagne` },
  { label: `FLL - Fort Lauderdale, Floride`, value: `FLL - Fort Lauderdale, Floride` },
  { label: `FLR - Florence (Peretola), Italie`, value: `FLR - Florence (Peretola), Italie` },
  { label: `FNA - Freetown (Lungi Airport), Sierra Leone`, value: `FNA - Freetown (Lungi Airport), Sierra Leone` },
  { label: `FNC - Funchal, Madère`, value: `FNC - Funchal, Madère` },
  { label: `FNI - Nîmes/Arles (Garons), France`, value: `FNI - Nîmes/Arles (Garons), France` },
  { label: `FNJ - Pyongyang, Corée du Nord`, value: `FNJ - Pyongyang, Corée du Nord` },
  { label: `FOR - Fortaleza, Brésil`, value: `FOR - Fortaleza, Brésil` },
  { label: `FPO - Freeport International Airport, Bahamas`, value: `FPO - Freeport International Airport, Bahamas` },
  { label: `FRA - Francfort (Rhein/Main), Allemagne`, value: `FRA - Francfort (Rhein/Main), Allemagne` },
  { label: `FRS - Flores, Guatemala`, value: `FRS - Flores, Guatemala` },
  { label: `FRU - Bishkek (Frunze), Kirghizistan`, value: `FRU - Bishkek (Frunze), Kirghizistan` },
  { label: `FSC - Figari, France`, value: `FSC - Figari, France` },
  { label: `FTG - Denver (Front Range Airport), USA`, value: `FTG - Denver (Front Range Airport), USA` },
  { label: `FTU - Fort Dauphin, Madagascar`, value: `FTU - Fort Dauphin, Madagascar` },
  { label: `FTY - Atlanta (Brown Field), USA`, value: `FTY - Atlanta (Brown Field), USA` },
  { label: `FUE - Fuerteventura, Canaries`, value: `FUE - Fuerteventura, Canaries` },
  { label: `GAE - Gabes, Tunisie`, value: `GAE - Gabes, Tunisie` },
  { label: `GAQ - Gao, Mali`, value: `GAQ - Gao, Mali` },
  { label: `GBE - Gaborone (Sir Seretse Khama), Botswana`, value: `GBE - Gaborone (Sir Seretse Khama), Botswana` },
  { label: `GBJ - Marie Galante, Guadeloupe`, value: `GBJ - Marie Galante, Guadeloupe` },
  { label: `GCI - Guernesey, îles Anglo-normandes`, value: `GCI - Guernesey, îles Anglo-normandes` },
  { label: `GCJ - Johannesburg (Grand Central), Af. du Sud`, value: `GCJ - Johannesburg (Grand Central), Af. du Sud` },
  { label: `GCM - Grand Cayman, Cayman Islands`, value: `GCM - Grand Cayman, Cayman Islands` },
  { label: `GDL - Guadalajara (Miguel Hidalgo), Mexique`, value: `GDL - Guadalajara (Miguel Hidalgo), Mexique` },
  { label: `GDN - Gdansk (Rebiechowo), Pologne`, value: `GDN - Gdansk (Rebiechowo), Pologne` },
  { label: `GDT - Grand Turk, Turks et Caicos`, value: `GDT - Grand Turk, Turks et Caicos` },
  { label: `GEA - Noumea, Nouvelle Caledonie`, value: `GEA - Noumea, Nouvelle Caledonie` },
  { label: `GEN - Oslo (Gardermoen), Norvège`, value: `GEN - Oslo (Gardermoen), Norvège` },
  { label: `GEO - Georgetown (Timehri), Guyana`, value: `GEO - Georgetown (Timehri), Guyana` },
  { label: `GGT- Great Exuma`, value: `GGT- Great Exuma` },
  { label: `GHA - Ghardaia (Noumerate), Algérie`, value: `GHA - Ghardaia (Noumerate), Algérie` },
  { label: `GIB - Gibraltar (North Front), Gibraltar`, value: `GIB - Gibraltar (North Front), Gibraltar` },
  { label: `GIG - Rio de Janeiro (Galeao), Brésil`, value: `GIG - Rio de Janeiro (Galeao), Brésil` },
  { label: `GLA - Glasgow (Abbotsichn), Ecosse`, value: `GLA - Glasgow (Abbotsichn), Ecosse` },
  { label: `GNB - Grenoble (Saint Geoirs), France`, value: `GNB - Grenoble (Saint Geoirs), France` },
  { label: `GND - Grenade (Pearls), Grenade`, value: `GND - Grenade (Pearls), Grenade` },
  { label: `GOA - Gênes Italie`, value: `GOA - Gênes Italie` },
  { label: `GOI - Goa, Inde`, value: `GOI - Goa, Inde` },
  { label: `GOQ - Golmud, Chine`, value: `GOQ - Golmud, Chine` },
  { label: `GOT - Göteborg (Landvetter), Suède`, value: `GOT - Göteborg (Landvetter), Suède` },
  { label: `GOU - Garoua, Cameroun`, value: `GOU - Garoua, Cameroun` },
  { label: `GPS - Galapagos Islands, Equateur`, value: `GPS - Galapagos Islands, Equateur` },
  { label: `GRO - Gerona (Costa Brava), Espagne`, value: `GRO - Gerona (Costa Brava), Espagne` },
  { label: `GRU - Sao Paulo (Guarulhos), Brésil`, value: `GRU - Sao Paulo (Guarulhos), Brésil` },
  { label: `GRW - Graciosa Island, Açores, Portugal`, value: `GRW - Graciosa Island, Açores, Portugal` },
  { label: `GRX - Granada, Espagne`, value: `GRX - Granada, Espagne` },
  { label: `GRZ - Graz (Thalerhof), Austriche`, value: `GRZ - Graz (Thalerhof), Austriche` },
  { label: `GSE - Göteborg (Save), Suède`, value: `GSE - Göteborg (Save), Suède` },
  { label: `GTN - Mount Cook (Glentan), Nlle-Zélande`, value: `GTN - Mount Cook (Glentan), Nlle-Zélande` },
  { label: `GUA - Guatemala City (La Aurora), Guatemala`, value: `GUA - Guatemala City (La Aurora), Guatemala` },
  { label: `GUM - Guam, Guam`, value: `GUM - Guam, Guam` },
  { label: `GVA - Genève (Genève-Cointrin), Suisse`, value: `GVA - Genève (Genève-Cointrin), Suisse` },
  { label: `GWY - Galway, Irlande`, value: `GWY - Galway, Irlande` },
  { label: `GYE - Guayaquil (Simon Bolivar), Equateur`, value: `GYE - Guayaquil (Simon Bolivar), Equateur` },
  { label: `GZM - Gozo, Malte`, value: `GZM - Gozo, Malte` },
  { label: `HAH - Moroni (Hahaya), Comores`, value: `HAH - Moroni (Hahaya), Comores` },
  { label: `HAJ - Hannovre (Langenhagen), Allemagne`, value: `HAJ - Hannovre (Langenhagen), Allemagne` },
  { label: `HAM - Hamburg (Fuhlsbuttel), Allemagne`, value: `HAM - Hamburg (Fuhlsbuttel), Allemagne` },
  { label: `HAN - Hanoi (Noi-Bai), Viêtnam`, value: `HAN - Hanoi (Noi-Bai), Viêtnam` },
  { label: `HAV - La Havane (Jose Marti), Cuba`, value: `HAV - La Havane (Jose Marti), Cuba` },
  { label: `HBA - Hobart, Tasmania, Australie`, value: `HBA - Hobart, Tasmania, Australie` },
  { label: `HEL - Helsinki (Vantaa), Finlande`, value: `HEL - Helsinki (Vantaa), Finlande` },
  { label: `HEM - Helsinki (Malmi), Finlande`, value: `HEM - Helsinki (Malmi), Finlande` },
  { label: `HER - Heraklion (Iraklion), Grèce`, value: `HER - Heraklion (Iraklion), Grèce` },
  { label: `HFA - Haifa, Israël`, value: `HFA - Haifa, Israël` },
  { label: `HGS - Freetown (Hastings), Sierra Leone`, value: `HGS - Freetown (Hastings), Sierra Leone` },
  { label: `HHN - Hahn, Allemagne`, value: `HHN - Hahn, Allemagne` },
  { label: `HIR - Honiara, (Henderson), Iles Salomon`, value: `HIR - Honiara, (Henderson), Iles Salomon` },
  { label: `HIX - Hiva Oa, Polynésie Française`, value: `HIX - Hiva Oa, Polynésie Française` },
  { label: `HKG - Hong Kong, Int'l Airport, Hong Kong`, value: `HKG - Hong Kong, Int'l Airport, Hong Kong` },
  { label: `HKT - Phuket, Thailande`, value: `HKT - Phuket, Thailande` },
  { label: `HLP - Jakarta (Halim Perdanakusuma), Indonésie`, value: `HLP - Jakarta (Halim Perdanakusuma), Indonésie` },
  { label: `HND - Tokyo (Haneda), Japon`, value: `HND - Tokyo (Haneda), Japon` },
  { label: `HNL - Honolulu International Airport, Hawaii`, value: `HNL - Honolulu International Airport, Hawaii` },
  { label: `HOG - Holguin, Cuba`, value: `HOG - Holguin, Cuba` },
  { label: `HOR - Horta (Faial Island), Açores`, value: `HOR - Horta (Faial Island), Açores` },
  { label: `HOU - Houston (William P. Hobby Airport), USA`, value: `HOU - Houston (William P. Hobby Airport), USA` },
  { label: `HRE - Harare, Zimbabwe`, value: `HRE - Harare, Zimbabwe` },
  { label: `HRG - Hurghada, Egypte`, value: `HRG - Hurghada, Egypte` },
  { label: `HSH - Las Vegas (Henderson Sky Harbor), USA`, value: `HSH - Las Vegas (Henderson Sky Harbor), USA` },
  { label: `HTB - Terre de Bas, Guadeloupe`, value: `HTB - Terre de Bas, Guadeloupe` },
  { label: `HUX - HUATULCO`, value: `HUX - HUATULCO` },
  { label: `HYD - Hyderabad, Inde`, value: `HYD - Hyderabad, Inde` },
  { label: `IAD - Washington Dulles International, USA`, value: `IAD - Washington Dulles International, USA` },
  { label: `IAH - Houston Intercontinental Airport, USA`, value: `IAH - Houston Intercontinental Airport, USA` },
  { label: `ICN-SEOUL`, value: `ICN-SEOUL` },
  { label: `IDY - Ile d'Yeu, France`, value: `IDY - Ile d'Yeu, France` },
  { label: `IEV - Kiev (Zhulyany), Ukraine`, value: `IEV - Kiev (Zhulyany), Ukraine` },
  { label: `IFN - Isfahan, Iran`, value: `IFN - Isfahan, Iran` },
  { label: `IGR - Iguazu (Cataratas del Iguazu), Argentine`, value: `IGR - Iguazu (Cataratas del Iguazu), Argentine` },
  { label: `IGU - Foz do Iguacu (Cataratas), Brésil`, value: `IGU - Foz do Iguacu (Cataratas), Brésil` },
  { label: `IKT - Irkutsk, Russie`, value: `IKT - Irkutsk, Russie` },
  { label: `ILP - Ile des Pins, Nlle Caledonie`, value: `ILP - Ile des Pins, Nlle Caledonie` },
  { label: `IND-Indianapolis`, value: `IND-Indianapolis` },
  { label: `INN - Innsbruck (Kranebitten), Austriche`, value: `INN - Innsbruck (Kranebitten), Austriche` },
  { label: `INU - Nauru International, Nauru`, value: `INU - Nauru International, Nauru` },
  { label: `INV - Inverness, Ecosse`, value: `INV - Inverness, Ecosse` },
  { label: `IOM - Isle of Man (Ronaldsway), Angleterre`, value: `IOM - Isle of Man (Ronaldsway), Angleterre` },
  { label: `IQT - Iquitos (F.S. Vigneta), Pérou`, value: `IQT - Iquitos (F.S. Vigneta), Pérou` },
  { label: `IST - Istanbul (Ataturk Internat.), Turquie`, value: `IST - Istanbul (Ataturk Internat.), Turquie` },
  { label: `ITM - Osaka (Itami Airport), Japon`, value: `ITM - Osaka (Itami Airport), Japon` },
  { label: `IXL - Leh, Inde`, value: `IXL - Leh, Inde` },
  { label: `IZM - Izmir (A. Menderes), Turquie`, value: `IZM - Izmir (A. Menderes), Turquie` },
  { label: `JAI - Jaipur, Inde`, value: `JAI - Jaipur, Inde` },
  { label: `JAO - Atlanta (Beaver Ruin Airport), USA`, value: `JAO - Atlanta (Beaver Ruin Airport), USA` },
  { label: `JAX - Jacksonville`, value: `JAX - Jacksonville` },
  { label: `JDH - Jodhpur, Inde`, value: `JDH - Jodhpur, Inde` },
  { label: `JED - Jeddah (King Abdul Aziz), Arabie Saoudite`, value: `JED - Jeddah (King Abdul Aziz), Arabie Saoudite` },
  { label: `JER - Jersey, Iles Anglo-normandes`, value: `JER - Jersey, Iles Anglo-normandes` },
  { label: `JFK - New York (John F. Kennedy), USA`, value: `JFK - New York (John F. Kennedy), USA` },
  { label: `JFM - Fremantle, Australie`, value: `JFM - Fremantle, Australie` },
  { label: `JHB - Johor Bahru, Malaisie`, value: `JHB - Johor Bahru, Malaisie` },
  { label: `JIB - Djibouti (Ambouli), Djibouti`, value: `JIB - Djibouti (Ambouli), Djibouti` },
  { label: `JKH - Chios, Grèce`, value: `JKH - Chios, Grèce` },
  { label: `JKT - Jakarta (Metropolitan Area), Indonésie`, value: `JKT - Jakarta (Metropolitan Area), Indonésie` },
  { label: `JMK - Mykonos, Grèce`, value: `JMK - Mykonos, Grèce` },
  { label: `JMY - Freetown (Manny Yoko), Sierra Leone`, value: `JMY - Freetown (Manny Yoko), Sierra Leone` },
  { label: `JNB - Johannesburg (J. Smuts), Afrique du Sud`, value: `JNB - Johannesburg (J. Smuts), Afrique du Sud` },
  { label: `JOG - Yogyakarta (Adisucipto), Indonésie`, value: `JOG - Yogyakarta (Adisucipto), Indonésie` },
  { label: `JRO - Kilimanjaro, Tanzanie`, value: `JRO - Kilimanjaro, Tanzanie` },
  { label: `JSA - Jaisalmer, Inde`, value: `JSA - Jaisalmer, Inde` },
  { label: `JSZ - St. Tropez, France`, value: `JSZ - St. Tropez, France` },
  { label: `JTR - Santorini, Grèce`, value: `JTR - Santorini, Grèce` },
  { label: `KBL - Kabul, Afghanistan`, value: `KBL - Kabul, Afghanistan` },
  { label: `KBP - Kiev (Borispol), Ukraine`, value: `KBP - Kiev (Borispol), Ukraine` },
  { label: `KBR - Kota Bharu, Malaisie`, value: `KBR - Kota Bharu, Malaisie` },
  { label: `KBV - Krabi, Thailande`, value: `KBV - Krabi, Thailande` },
  { label: `KCH - Kuching, Malaisie`, value: `KCH - Kuching, Malaisie` },
  { label: `KEF - Reykjavik (Keflavik), Islande`, value: `KEF - Reykjavik (Keflavik), Islande` },
  { label: `KGD - Kaliningrad, Russie`, value: `KGD - Kaliningrad, Russie` },
  { label: `KGL - Kigali (Kanombe), Rwanda`, value: `KGL - Kigali (Kanombe), Rwanda` },
  { label: `KHH - Kaohsiung International, Taiwan`, value: `KHH - Kaohsiung International, Taiwan` },
  { label: `KHI - Karachi, Pakistan`, value: `KHI - Karachi, Pakistan` },
  { label: `KIN - Kingston (Norman Manley), Jamaique`, value: `KIN - Kingston (Norman Manley), Jamaique` },
  { label: `KIV - Chisinau (Kishinev), Moldavie`, value: `KIV - Chisinau (Kishinev), Moldavie` },
  { label: `KIX - Osaka (Kansai Airport), Japon`, value: `KIX - Osaka (Kansai Airport), Japon` },
  { label: `KMG - Kunming (Wujlaba), Chine`, value: `KMG - Kunming (Wujlaba), Chine` },
  { label: `KOI - kirkwall`, value: `KOI - kirkwall` },
  { label: `KRK - Krakow (Balice), Pologne`, value: `KRK - Krakow (Balice), Pologne` },
  { label: `KRT - Khartoum, Soudan`, value: `KRT - Khartoum, Soudan` },
  { label: `KTM - Kathmandu (Tribhuvan), Népal`, value: `KTM - Kathmandu (Tribhuvan), Népal` },
  { label: `KTP - Kingston (Tinson), Jamaica`, value: `KTP - Kingston (Tinson), Jamaica` },
  { label: `KTW - Katowice (Pyrzowice), Pologne`, value: `KTW - Katowice (Pyrzowice), Pologne` },
  { label: `KUL - Kuala Lumpur (Subang), Malaisie`, value: `KUL - Kuala Lumpur (Subang), Malaisie` },
  { label: `KWI - Kuwait International Airport, Kuwait`, value: `KWI - Kuwait International Airport, Kuwait` },
  { label: `KWL - Guilin, Chine`, value: `KWL - Guilin, Chine` },
  { label: `LAD - Luanda (4 de Fevereiro), Angola`, value: `LAD - Luanda (4 de Fevereiro), Angola` },
  { label: `LAI - Lannion, France`, value: `LAI - Lannion, France` },
  { label: `LAS - Las Vegas (McCarran Internat.), USA`, value: `LAS - Las Vegas (McCarran Internat.), USA` },
  { label: `LAU - Lamu (Mwana), Kenya`, value: `LAU - Lamu (Mwana), Kenya` },
  { label: `LAX - Los Angeles Internat. Airport, USA`, value: `LAX - Los Angeles Internat. Airport, USA` },
  { label: `LBA - Leeds/Bradford, Angleterre`, value: `LBA - Leeds/Bradford, Angleterre` },
  { label: `LBD - Khudzhand, Tajikistan`, value: `LBD - Khudzhand, Tajikistan` },
  { label: `LBG - Paris (Le Bourget), France`, value: `LBG - Paris (Le Bourget), France` },
  { label: `LBH - Sydney (Palm Beach), Australie`, value: `LBH - Sydney (Palm Beach), Australie` },
  { label: `LBI - Albi, France`, value: `LBI - Albi, France` },
  { label: `LBV - Libreville (Leon M'Ba), Gabon`, value: `LBV - Libreville (Leon M'Ba), Gabon` },
  { label: `LBY - La Baule, France`, value: `LBY - La Baule, France` },
  { label: `LCA - Larnaca, Chypre`, value: `LCA - Larnaca, Chypre` },
  { label: `LCE - La Ceiba (Goloson), Honduras`, value: `LCE - La Ceiba (Goloson), Honduras` },
  { label: `LCG - La Coruna, Espagne`, value: `LCG - La Coruna, Espagne` },
  { label: `LCY - Londres (City Airport), Angleterre`, value: `LCY - Londres (City Airport), Angleterre` },
  { label: `LDE - Lourdes/Tarbes (Ossun), France`, value: `LDE - Lourdes/Tarbes (Ossun), France` },
  { label: `LED - St. Petersburg (Pulkowo-2), Russie`, value: `LED - St. Petersburg (Pulkowo-2), Russie` },
  { label: `LEH - Le Havre (Octeville), France`, value: `LEH - Le Havre (Octeville), France` },
  { label: `LEJ - Leipzig (Schkeuditz), Allemagne`, value: `LEJ - Leipzig (Schkeuditz), Allemagne` },
  { label: `LEN - LEON, Espagne`, value: `LEN - LEON, Espagne` },
  { label: `LEX-Lexington, Kentucky`, value: `LEX-Lexington, Kentucky` },
  { label: `LFW - Lome (Tokoin), Togo`, value: `LFW - Lome (Tokoin), Togo` },
  { label: `LGA - New York (La Guardia Airport), USA`, value: `LGA - New York (La Guardia Airport), USA` },
  { label: `LGG - Liege (Bierset), Belgique`, value: `LGG - Liege (Bierset), Belgique` },
  { label: `LGK - Langkawi, Malaisie`, value: `LGK - Langkawi, Malaisie` },
  { label: `LGW - Londres (Gatwick), Angleterre`, value: `LGW - Londres (Gatwick), Angleterre` },
  { label: `LHE - Lahore, Pakistan`, value: `LHE - Lahore, Pakistan` },
  { label: `LHR - Londres (Heathrow), Angleterre`, value: `LHR - Londres (Heathrow), Angleterre` },
  { label: `LIG - Limoges (Bellegarde), France`, value: `LIG - Limoges (Bellegarde), France` },
  { label: `Lihue - Kawaii`, value: `Lihue - Kawaii` },
  { label: `LIL - Lille (Lesquin), France`, value: `LIL - Lille (Lesquin), France` },
  { label: `LIM - Lima (J. Chavez Internacional), Pérou`, value: `LIM - Lima (J. Chavez Internacional), Pérou` },
  { label: `LIN - Milan (Linate), Italie`, value: `LIN - Milan (Linate), Italie` },
  { label: `LIR- Liberia, Costa Rica`, value: `LIR- Liberia, Costa Rica` },
  { label: `LIS - Lisbone (Portela de Sacavem), Portugal`, value: `LIS - Lisbone (Portela de Sacavem), Portugal` },
  { label: `LJU - Ljubljana (Brnik), Slovénie`, value: `LJU - Ljubljana (Brnik), Slovénie` },
  { label: `LLI - Lalibella, Ethiopie`, value: `LLI - Lalibella, Ethiopie` },
  { label: `LLW - Lilongwe (Kamuzu Internat.), Malawi`, value: `LLW - Lilongwe (Kamuzu Internat.), Malawi` },
  { label: `LOH - Loja, Equateur`, value: `LOH - Loja, Equateur` },
  { label: `LON - Londres (Metropolitan Area), Angleterre`, value: `LON - Londres (Metropolitan Area), Angleterre` },
  { label: `LOS - Lagos (Murtala Muhammed), Nigeria`, value: `LOS - Lagos (Murtala Muhammed), Nigeria` },
  { label: `LPA - Las Palmas/Gran Canaria, Canaries`, value: `LPA - Las Palmas/Gran Canaria, Canaries` },
  { label: `LPB - La Paz (El Alto), Bolivie`, value: `LPB - La Paz (El Alto), Bolivie` },
  { label: `LPL - Liverpool (Speke Airport), Angleterre`, value: `LPL - Liverpool (Speke Airport), Angleterre` },
  { label: `LPQ - Luang Prabang, Laos`, value: `LPQ - Luang Prabang, Laos` },
  { label: `LPY - Le Puy, France`, value: `LPY - Le Puy, France` },
  { label: `LRH - La Rochelle (Laleu), France`, value: `LRH - La Rochelle (Laleu), France` },
  { label: `LRM - La Romana, République Dominicaine`, value: `LRM - La Romana, République Dominicaine` },
  { label: `LRT - Lorient (Lann-Bihouet), France`, value: `LRT - Lorient (Lann-Bihouet), France` },
  { label: `LSI - Lerwick, Shetland`, value: `LSI - Lerwick, Shetland` },
  { label: `LSS - Les Saintes, Guadeloupe`, value: `LSS - Les Saintes, Guadeloupe` },
  { label: `LTD - Ghadames, Libye`, value: `LTD - Ghadames, Libye` },
  { label: `LTN - Luton Airport, Angleterre`, value: `LTN - Luton Airport, Angleterre` },
  { label: `LUD - Lüderitz, Namibie`, value: `LUD - Lüderitz, Namibie` },
  { label: `LUG - Lugano (Agno), Suisse`, value: `LUG - Lugano (Agno), Suisse` },
  { label: `LUN - Lusaka Internat. Airport, Zambie`, value: `LUN - Lusaka Internat. Airport, Zambie` },
  { label: `LUX - Luxembourg (Findel), Luxembourg`, value: `LUX - Luxembourg (Findel), Luxembourg` },
  { label: `LVS - Las Vegas Municipal Airport, USA`, value: `LVS - Las Vegas Municipal Airport, USA` },
  { label: `LXA - Lhasa, Tibet`, value: `LXA - Lhasa, Tibet` },
  { label: `LXR - Luxor, Égypte`, value: `LXR - Luxor, Égypte` },
  { label: `LYN - Lyon (Bron), France`, value: `LYN - Lyon (Bron), France` },
  { label: `LYR - Svalbard (Longyearbyen), Norvège`, value: `LYR - Svalbard (Longyearbyen), Norvège` },
  { label: `LYS - Lyon (St Exupery), France`, value: `LYS - Lyon (St Exupery), France` },
  { label: `MAA - Madras (Meenambakkam), Inde`, value: `MAA - Madras (Meenambakkam), Inde` },
  { label: `MAD - Madrid (Barajas), Espagne`, value: `MAD - Madrid (Barajas), Espagne` },
  { label: `MAH - Mahon (Menorca), Baleéares`, value: `MAH - Mahon (Menorca), Baleéares` },
  { label: `MAN - Manchester (Ringway Int.), Angleterre`, value: `MAN - Manchester (Ringway Int.), Angleterre` },
  { label: `MAO - Manaus (Eduardo Gomes), Brésil`, value: `MAO - Manaus (Eduardo Gomes), Brésil` },
  { label: `MBA - Mombasa (Moi Internat.), Kenya`, value: `MBA - Mombasa (Moi Internat.), Kenya` },
  { label: `MBJ - Montego Bay (Sangster), Jamaique`, value: `MBJ - Montego Bay (Sangster), Jamaique` },
  { label: `MCO - Orlando International Airport, USA`, value: `MCO - Orlando International Airport, USA` },
  { label: `MCP - Macapa,Brésil`, value: `MCP - Macapa,Brésil` },
  { label: `MCT - Muscat (Seeb), Oman`, value: `MCT - Muscat (Seeb), Oman` },
  { label: `MDL - Mandalay, Myanmar`, value: `MDL - Mandalay, Myanmar` },
  { label: `MDQ - Mar del Plata, Argentine`, value: `MDQ - Mar del Plata, Argentine` },
  { label: `MDW - Chicago (Midway Airport), USA`, value: `MDW - Chicago (Midway Airport), USA` },
  { label: `MDZ - Mendoza (El Plumerillo), Argentine`, value: `MDZ - Mendoza (El Plumerillo), Argentine` },
  { label: `MEB - Melbourne (Essendon), Australie`, value: `MEB - Melbourne (Essendon), Australie` },
  { label: `MEK - Meknes, Maroc`, value: `MEK - Meknes, Maroc` },
  { label: `MEL - Melbourne (Tullamarine), Australie`, value: `MEL - Melbourne (Tullamarine), Australie` },
  { label: `MES - Medan (Polonia), Indonésie`, value: `MES - Medan (Polonia), Indonésie` },
  { label: `MEX - Mexico City, (Benito Juarez), Mexique`, value: `MEX - Mexico City, (Benito Juarez), Mexique` },
  { label: `MGA - Managua (A. Sandino), Nicaragua`, value: `MGA - Managua (A. Sandino), Nicaragua` },
  { label: `MHB - Auckland (Mechanics Bay), Nlle-Zélande`, value: `MHB - Auckland (Mechanics Bay), Nlle-Zélande` },
  { label: `MHD - Mashad, Iran`, value: `MHD - Mashad, Iran` },
  { label: `MIA - Miami International Airport, USA`, value: `MIA - Miami International Airport, USA` },
  { label: `MID - Merida (M. C. Rejon), Mexique`, value: `MID - Merida (M. C. Rejon), Mexique` },
  { label: `MIL - Milan (Metropolitan Area), Italie`, value: `MIL - Milan (Metropolitan Area), Italie` },
  { label: `MIR - Monastir (Skanes), Tunisie`, value: `MIR - Monastir (Skanes), Tunisie` },
  { label: `MJN - Majunga (Amborovy), Madagascar`, value: `MJN - Majunga (Amborovy), Madagascar` },
  { label: `MJV - Murcie (San Javier), Espagne`, value: `MJV - Murcie (San Javier), Espagne` },
  { label: `MKZ - Malacca (Batu Berenclum), Malaisie`, value: `MKZ - Malacca (Batu Berenclum), Malaisie` },
  { label: `MLA - Malte (Luqa), Malta`, value: `MLA - Malte (Luqa), Malta` },
  { label: `MLE - Male (Hulule), Maldives`, value: `MLE - Male (Hulule), Maldives` },
  { label: `MLW - Monrovia (Spriggs Payne), Liberia`, value: `MLW - Monrovia (Spriggs Payne), Liberia` },
  { label: `MMA - Malmo, Suède`, value: `MMA - Malmo, Suède` },
  { label: `MMK - Murmansk, Russie`, value: `MMK - Murmansk, Russie` },
  { label: `MMO - Maio, Cape Verde`, value: `MMO - Maio, Cape Verde` },
  { label: `MMX - Malmo (Sturup), Suède`, value: `MMX - Malmo (Sturup), Suède` },
  { label: `MNL - Manille (Ninoy Aquino), Philippines`, value: `MNL - Manille (Ninoy Aquino), Philippines` },
  { label: `MOF - Maumere, Indonésie`, value: `MOF - Maumere, Indonésie` },
  { label: `MOW - Moscou (Metropolitan Area), Russie`, value: `MOW - Moscou (Metropolitan Area), Russie` },
  { label: `MPL - Montpellier (Méditeranée), France`, value: `MPL - Montpellier (Méditeranée), France` },
  { label: `MPM - Maputo, Mozambique`, value: `MPM - Maputo, Mozambique` },
  { label: `MPN - Mount Pleasant, Falklands`, value: `MPN - Mount Pleasant, Falklands` },
  { label: `MRD - Merida, Venezuela`, value: `MRD - Merida, Venezuela` },
  { label: `MRI - Anchorage (Merrill Field), Alaska`, value: `MRI - Anchorage (Merrill Field), Alaska` },
  { label: `MRS - Marseille (Marignane), France`, value: `MRS - Marseille (Marignane), France` },
  { label: `MRU - Plaisance (S. Ramgoolam), Maurice`, value: `MRU - Plaisance (S. Ramgoolam), Maurice` },
  { label: `MRX - Margarita Island, Venezuela`, value: `MRX - Margarita Island, Venezuela` },
  { label: `MSB - St. Maarten (Marigot), Antilles Néerl.`, value: `MSB - St. Maarten (Marigot), Antilles Néerl.` },
  { label: `MSP - Minneapolis/St. Paul, USA`, value: `MSP - Minneapolis/St. Paul, USA` },
  { label: `MSQ - Minsk (Loshitsa), Belarusse`, value: `MSQ - Minsk (Loshitsa), Belarusse` },
  { label: `MSR - Munster (Greven), Allemagne`, value: `MSR - Munster (Greven), Allemagne` },
  { label: `MST - Maastricht (Zuid-Limburg), Pays-Bas`, value: `MST - Maastricht (Zuid-Limburg), Pays-Bas` },
  { label: `MSU - Maseru (Moshoeshoe), Lesotho`, value: `MSU - Maseru (Moshoeshoe), Lesotho` },
  { label: `MSW - Massawa, Erythrée`, value: `MSW - Massawa, Erythrée` },
  { label: `MSY - New Orleans (Moisant Field), USA`, value: `MSY - New Orleans (Moisant Field), USA` },
  { label: `MDE - Medellin `, value: `MDE - Medellin ` },
  { label: `MTY - Monterrey (M. Escobedo), Mexique`, value: `MTY - Monterrey (M. Escobedo), Mexique` },
  { label: `MUB - Maun, Botswana`, value: `MUB - Maun, Botswana` },
  { label: `MUC - Munich (F. J. Strauss), Allemagne`, value: `MUC - Munich (F. J. Strauss), Allemagne` },
  { label: `MUV - Philadelphie (Mustin ALF), USA`, value: `MUV - Philadelphie (Mustin ALF), USA` },
  { label: `MVD - Montevideo (Carrasco), Uruguay`, value: `MVD - Montevideo (Carrasco), Uruguay` },
  { label: `MXP - Milan (Malpensa), Italie`, value: `MXP - Milan (Malpensa), Italie` },
  { label: `MYD - Malindi, Kenya`, value: `MYD - Malindi, Kenya` },
  { label: `MZI - Mopti (Barbe), Mali`, value: `MZI - Mopti (Barbe), Mali` },
  { label: `MZM - Metz (Frescaty), France`, value: `MZM - Metz (Frescaty), France` },
  { label: `MZO - Manzanillo de Cuba`, value: `MZO - Manzanillo de Cuba` },
  { label: `MZT - MAZATLAN`, value: `MZT - MAZATLAN` },
  { label: `NAN - Nadi, Fidji`, value: `NAN - Nadi, Fidji` },
  { label: `NAP - Naples (Capodichino), Italie`, value: `NAP - Naples (Capodichino), Italie` },
  { label: `NAS - Nassau, Bahamas`, value: `NAS - Nassau, Bahamas` },
  { label: `NAT - Natal, Brésil`, value: `NAT - Natal, Brésil` },
  { label: `NBL - San Blas, Panama`, value: `NBL - San Blas, Panama` },
  { label: `NBO - Nairobi (Jomo Kenyatta), Kenya`, value: `NBO - Nairobi (Jomo Kenyatta), Kenya` },
  { label: `NCE - Nice (Côte d'Azur), France`, value: `NCE - Nice (Côte d'Azur), France` },
  { label: `NCL - Newcastle, Angleterre`, value: `NCL - Newcastle, Angleterre` },
  { label: `NDJ - N'Djamena, Tchad`, value: `NDJ - N'Djamena, Tchad` },
  { label: `NEW - New Orleans (Lakefront Airport), USA`, value: `NEW - New Orleans (Lakefront Airport), USA` },
  { label: `NGO - Nagoya (Komaki), Japon`, value: `NGO - Nagoya (Komaki), Japon` },
  { label: `NIC - Nicosia, Chypre`, value: `NIC - Nicosia, Chypre` },
  { label: `NIM - Niamey, Niger`, value: `NIM - Niamey, Niger` },
  { label: `NKC - Nouakchott, Mauritanie`, value: `NKC - Nouakchott, Mauritanie` },
  { label: `NLO - Kinshasa (N'Dolo), RDC`, value: `NLO - Kinshasa (N'Dolo), RDC` },
  { label: `NOS - Nossi-Be (Fascene), Madagascar`, value: `NOS - Nossi-Be (Fascene), Madagascar` },
  { label: `NOU - Noumea (La Tontouta), Nlle Caledonie`, value: `NOU - Noumea (La Tontouta), Nlle Caledonie` },
  { label: `NPS - Honolulu (Ford Island), Hawaii`, value: `NPS - Honolulu (Ford Island), Hawaii` },
  { label: `NRT - Tokyo (Narita), Japon`, value: `NRT - Tokyo (Narita), Japon` },
  { label: `NSI - Yaounde (Nsimalen Internat.), Cameroun`, value: `NSI - Yaounde (Nsimalen Internat.), Cameroun` },
  { label: `NTE - Nantes (Château Bougon), France`, value: `NTE - Nantes (Château Bougon), France` },
  { label: `NTO - Santo Antao, Cape Verde`, value: `NTO - Santo Antao, Cape Verde` },
  { label: `NTR - Monterrey (Aeropuerto Norte), Mexique`, value: `NTR - Monterrey (Aeropuerto Norte), Mexique` },
  { label: `NUE - Nurenberg, Allemagne`, value: `NUE - Nurenberg, Allemagne` },
  { label: `NWA - Moheli, Comores`, value: `NWA - Moheli, Comores` },
  { label: `NYC - New York (Metropolitan Area), USA`, value: `NYC - New York (Metropolitan Area), USA` },
  { label: `OAK - Oakland International Airport, USA`, value: `OAK - Oakland International Airport, USA` },
  { label: `OCO - San Jose (El Coco), Costa Rica`, value: `OCO - San Jose (El Coco), Costa Rica` },
  { label: `OCW - Washington (Warren Field), USA`, value: `OCW - Washington (Warren Field), USA` },
  { label: `ODB - Cordou (San Jeronimo), Espagne`, value: `ODB - Cordou (San Jeronimo), Espagne` },
  { label: `ODE - Odense (Beldringe), Danemark`, value: `ODE - Odense (Beldringe), Danemark` },
  { label: `ODS - Odessa (Tsentrainy), Ukraine`, value: `ODS - Odessa (Tsentrainy), Ukraine` },
  { label: `OGG- Maui, KAHULUI`, value: `OGG- Maui, KAHULUI` },
  { label: `OHD - Ohrid, Macédoine`, value: `OHD - Ohrid, Macédoine` },
  { label: `OKD - Sapporo (Okadama), Japon`, value: `OKD - Sapporo (Okadama), Japon` },
  { label: `OLB - Olbia (Costa Smeralda), Italie`, value: `OLB - Olbia (Costa Smeralda), Italie` },
  { label: `OPF - Miami (Opa Locka Airport), USA`, value: `OPF - Miami (Opa Locka Airport), USA` },
  { label: `OPO - Porto (Pedras Rubras), Portugal`, value: `OPO - Porto (Pedras Rubras), Portugal` },
  { label: `ORA - Oran, Algérie`, value: `ORA - Oran, Algérie` },
  { label: `ORD - Chicago (O'Hare International), USA`, value: `ORD - Chicago (O'Hare International), USA` },
  { label: `ORG - Paramaribo (Zorg en Hoop), Suriname`, value: `ORG - Paramaribo (Zorg en Hoop), Suriname` },
  { label: `ORK - Cork, Irlande`, value: `ORK - Cork, Irlande` },
  { label: `ORN - Oran (Es Senia), Algérie`, value: `ORN - Oran (Es Senia), Algérie` },
  { label: `ORY - Paris (Orly), France`, value: `ORY - Paris (Orly), France` },
  { label: `OSA - Osaka (Metropolitan Area), Japon`, value: `OSA - Osaka (Metropolitan Area), Japon` },
  { label: `OSL - Oslo (Metropolitan Area), Norvège`, value: `OSL - Oslo (Metropolitan Area), Norvège` },
  { label: `OSS - Osh, Kyrgyzstan`, value: `OSS - Osh, Kyrgyzstan` },
  { label: `OST - Ostende, Belgique`, value: `OST - Ostende, Belgique` },
  { label: `OTP - Bucarest (Otopeni), Roumanie`, value: `OTP - Bucarest (Otopeni), Roumanie` },
  { label: `OUA - Ouagadougou, Burkina Faso`, value: `OUA - Ouagadougou, Burkina Faso` },
  { label: `OUD - Oujda (Les Angades), Maroc`, value: `OUD - Oujda (Les Angades), Maroc` },
  { label: `OVD - Oviedo (Asturias), Espagne`, value: `OVD - Oviedo (Asturias), Espagne` },
  { label: `OZZ - Ouarzazate, Maroc`, value: `OZZ - Ouarzazate, Maroc` },
  { label: `PAC - Panama City (Paitilla), Panama`, value: `PAC - Panama City (Paitilla), Panama` },
  { label: `PAP - Port-au-Prince, Haïti`, value: `PAP - Port-au-Prince, Haïti` },
  { label: `PAR - Paris (Metropolian Area), France`, value: `PAR - Paris (Metropolian Area), France` },
  { label: `PAS - Paros, Grèce`, value: `PAS - Paros, Grèce` },
  { label: `PBC - Puebla, Mexique`, value: `PBC - Puebla, Mexique` },
  { label: `PBG-PLATTSBURGH`, value: `PBG-PLATTSBURGH` },
  { label: `PBH - Paro, Bhoutan`, value: `PBH - Paro, Bhoutan` },
  { label: `PBM - Paramaribo (J. A. Pengel), Suriname`, value: `PBM - Paramaribo (J. A. Pengel), Suriname` },
  { label: `PCP - Principe, Sao Tomé et Principe`, value: `PCP - Principe, Sao Tomé et Principe` },
  { label: `PDK - Atlanta (Dekalb-Peachtree Airport), USA`, value: `PDK - Atlanta (Dekalb-Peachtree Airport), USA` },
  { label: `PDL - Ponta Delgada (Sao Miguel), Açores`, value: `PDL - Ponta Delgada (Sao Miguel), Açores` },
  { label: `PDV - Plovdiv, Bulgarie`, value: `PDV - Plovdiv, Bulgarie` },
  { label: `PDX - Portland International Airport, USA`, value: `PDX - Portland International Airport, USA` },
  { label: `PEK - Beijing (Capital), Chine`, value: `PEK - Beijing (Capital), Chine` },
  { label: `PEM - Puerto Maldonado (Padre Aldamiz), Pérou`, value: `PEM - Puerto Maldonado (Padre Aldamiz), Pérou` },
  { label: `PEN - Penang International, Malaisie`, value: `PEN - Penang International, Malaisie` },
  { label: `PER - Perth, Australie`, value: `PER - Perth, Australie` },
  { label: `PEW - Peshawar, Pakistan`, value: `PEW - Peshawar, Pakistan` },
  { label: `PFO - Paphos (International), Chypre`, value: `PFO - Paphos (International), Chypre` },
  { label: `PGF - Perpignan (Rivesaltes), France`, value: `PGF - Perpignan (Rivesaltes), France` },
  { label: `PGX - Perigueux (Bassillac), France`, value: `PGX - Perigueux (Bassillac), France` },
  { label: `PHC - Port Harcourt (Omagwa), Nigeria`, value: `PHC - Port Harcourt (Omagwa), Nigeria` },
  { label: `PHL - Philadelphia International Airport, USA`, value: `PHL - Philadelphia International Airport, USA` },
  { label: `PHW - Phalaborwa, Afrique du Sud`, value: `PHW - Phalaborwa, Afrique du Sud` },
  { label: `PHX - Phoenix (Sky Harbor International), USA`, value: `PHX - Phoenix (Sky Harbor International), USA` },
  { label: `PIS - Poitiers (Biard), France`, value: `PIS - Poitiers (Biard), France` },
  { label: `PLH - Plymouth (Roborough Airport), Angleterre`, value: `PLH - Plymouth (Roborough Airport), Angleterre` },
  { label: `PLP - La Palma, Canaries`, value: `PLP - La Palma, Canaries` },
  { label: `PLS - Providenciales`, value: `PLS - Providenciales` },
  { label: `PLU - Belo Horizonte, Brésil`, value: `PLU - Belo Horizonte, Brésil` },
  { label: `PMA - Pemba Island, Tanzanie`, value: `PMA - Pemba Island, Tanzanie` },
  { label: `PMC - Puerto Montt (El Tepual), Chili`, value: `PMC - Puerto Montt (El Tepual), Chili` },
  { label: `PMF - Parme, Italie`, value: `PMF - Parme, Italie` },
  { label: `PMI - Palma de Mallorca, Baléares`, value: `PMI - Palma de Mallorca, Baléares` },
  { label: `PMO - Palerme (Punta Raisi), Italie`, value: `PMO - Palerme (Punta Raisi), Italie` },
  { label: `PMV - Marguarita, Vénézuela`, value: `PMV - Marguarita, Vénézuela` },
  { label: `PNA - Pamplona, Espagne`, value: `PNA - Pamplona, Espagne` },
  { label: `PNH - Phnom Penh (Pochentong), Cambodge`, value: `PNH - Phnom Penh (Pochentong), Cambodge` },
  { label: `PNI - Pohnpei International Airport, Micronesia`, value: `PNI - Pohnpei International Airport, Micronesia` },
  { label: `PNK - Pontianak (Supadio), Indonésie`, value: `PNK - Pontianak (Supadio), Indonésie` },
  { label: `PNR - Pointe Noire, Congo`, value: `PNR - Pointe Noire, Congo` },
  { label: `PNS - PENSACOLA FLORIDE`, value: `PNS - PENSACOLA FLORIDE` },
  { label: `POA - Porto Alegre (Salgado Filho), Brésil`, value: `POA - Porto Alegre (Salgado Filho), Brésil` },
  { label: `POI - Potosi (Capitan Nicolas Rojas), Bolivie`, value: `POI - Potosi (Capitan Nicolas Rojas), Bolivie` },
  { label: `POL - Pemba (Porto Amelia), Mozambique`, value: `POL - Pemba (Porto Amelia), Mozambique` },
  { label: `POM - Port Moresby, Papouasie-Nlle-Guinée`, value: `POM - Port Moresby, Papouasie-Nlle-Guinée` },
  { label: `POP - Puerto Plata, République Dominicaine`, value: `POP - Puerto Plata, République Dominicaine` },
  { label: `POS - Port of Spain (Piarco), Trinidad et Tobago`, value: `POS - Port of Spain (Piarco), Trinidad et Tobago` },
  { label: `PPG - Pago Pago, Samoa US`, value: `PPG - Pago Pago, Samoa US` },
  { label: `PPS - Puerto Princesa International, Philippines`, value: `PPS - Puerto Princesa International, Philippines` },
  { label: `PPT - Papeete (Faaa), Polynésie Française`, value: `PPT - Papeete (Faaa), Polynésie Française` },
  { label: `PRG - Prague (Ruzyne), Rép. Tchèque`, value: `PRG - Prague (Ruzyne), Rép. Tchèque` },
  { label: `PRI - Praslin, Seychelles`, value: `PRI - Praslin, Seychelles` },
  { label: `PRJ - Capri, Italie`, value: `PRJ - Capri, Italie` },
  { label: `PSA - Pise (Galilei), Italie`, value: `PSA - Pise (Galilei), Italie` },
  { label: `PSY - Port Stanley, Falklands`, value: `PSY - Port Stanley, Falklands` },
  { label: `PTP - Pointe-a-Pitre (Le Raizet), Guadeloupe`, value: `PTP - Pointe-a-Pitre (Le Raizet), Guadeloupe` },
  { label: `PTY - Panama City (O. Torrijos Herrara), Panama`, value: `PTY - Panama City (O. Torrijos Herrara), Panama` },
  { label: `PUF - Pau (Pont Long Uzein), France`, value: `PUF - Pau (Pont Long Uzein), France` },
  { label: `PUJ - Punta Cana, République Dominicaine`, value: `PUJ - Punta Cana, République Dominicaine` },
  { label: `PUS - Pusan (Kimhae), Corée du Sud`, value: `PUS - Pusan (Kimhae), Corée du Sud` },
  { label: `PUW-Pullman,Washington`, value: `PUW-Pullman,Washington` },
  { label: `PVG - Shanghai (Pu Dong), Chine`, value: `PVG - Shanghai (Pu Dong), Chine` },
  { label: `PVR - Puerto Vallarta (G. D. Ordaz), Mexique`, value: `PVR - Puerto Vallarta (G. D. Ordaz), Mexique` },
  { label: `PYX - Pattaya, Thaïlande`, value: `PYX - Pattaya, Thaïlande` },
  { label: `PZU - Port Soudan, Soudan`, value: `PZU - Port Soudan, Soudan` },
  { label: `QDU - Dusseldorf, Allemagne`, value: `QDU - Dusseldorf, Allemagne` },
  { label: `QDV - Denver (Metropolitan Area), USA`, value: `QDV - Denver (Metropolitan Area), USA` },
  { label: `QGN - Tarragona (Reus), Espagne`, value: `QGN - Tarragona (Reus), Espagne` },
  { label: `QKA - Karlsruhe (Forchheim), Allemagne`, value: `QKA - Karlsruhe (Forchheim), Allemagne` },
  { label: `QLA - Los Angeles (Metropolitan Area), USA`, value: `QLA - Los Angeles (Metropolitan Area), USA` },
  { label: `QLI - Limassol, Chypre`, value: `QLI - Limassol, Chypre` },
  { label: `QLJ - Lucerne, Suisse`, value: `QLJ - Lucerne, Suisse` },
  { label: `QLS - Lausanne (La Blecherette), Suisse`, value: `QLS - Lausanne (La Blecherette), Suisse` },
  { label: `QPA - Padou, Italie`, value: `QPA - Padou, Italie` },
  { label: `QPG - Singapour (Paya Lebar), Singapour`, value: `QPG - Singapour (Paya Lebar), Singapour` },
  { label: `QRA - Jo'burg (Randgermiston), Af. du Sud`, value: `QRA - Jo'burg (Randgermiston), Af. du Sud` },
  { label: `QSF - San Francisco (Metropolitan Area), USA`, value: `QSF - San Francisco (Metropolitan Area), USA` },
  { label: `QSM - St. Maarten, Antilles Néerlandaises`, value: `QSM - St. Maarten, Antilles Néerlandaises` },
  { label: `QSO - Sousse, Tunisie`, value: `QSO - Sousse, Tunisie` },
  { label: `QTW - Taejon, Corée du Sud`, value: `QTW - Taejon, Corée du Sud` },
  { label: `RAI - Praia, Sao Tiago, Cape Verde`, value: `RAI - Praia, Sao Tiago, Cape Verde` },
  { label: `RAK - Marrakech (Menara), Maroc`, value: `RAK - Marrakech (Menara), Maroc` },
  { label: `RBA - Rabat (Sale), Maroc`, value: `RBA - Rabat (Sale), Maroc` },
  { label: `RBD - Dallas (Redbird Airport), USA`, value: `RBD - Dallas (Redbird Airport), USA` },
  { label: `RDU - Raleigh-Durham (Int'l Airport), USA`, value: `RDU - Raleigh-Durham (Int'l Airport), USA` },
  { label: `RDZ - Rodez, France`, value: `RDZ - Rodez, France` },
  { label: `REC - Recife (Guararapes), Brésil`, value: `REC - Recife (Guararapes), Brésil` },
  { label: `REK - Reykjavik, Islande`, value: `REK - Reykjavik, Islande` },
  { label: `REP - Siem Reap (Angkor), Cambodge`, value: `REP - Siem Reap (Angkor), Cambodge` },
  { label: `RGN - Yangoon (Mingaladon), Myanmar`, value: `RGN - Yangoon (Mingaladon), Myanmar` },
  { label: `RHE - Reims (Champagne), France`, value: `RHE - Reims (Champagne), France` },
  { label: `RHO - Rhodes (Diagoras/Maritsa), Grèce`, value: `RHO - Rhodes (Diagoras/Maritsa), Grèce` },
  { label: `RIH- RIO HATO, PANAMA`, value: `RIH- RIO HATO, PANAMA` },
  { label: `RIO - Rio de Janeiro (Metropolitan Area), Brésil`, value: `RIO - Rio de Janeiro (Metropolitan Area), Brésil` },
  { label: `RIX - Riga (Spilve), Laettonie`, value: `RIX - Riga (Spilve), Laettonie` },
  { label: `RJK - Rijeka, Croatie`, value: `RJK - Rijeka, Croatie` },
  { label: `RKE - Copenhagen (Roskilde), Danemark`, value: `RKE - Copenhagen (Roskilde), Danemark` },
  { label: `RML - Colombo, Sri Lanka`, value: `RML - Colombo, Sri Lanka` },
  { label: `RNS - Rennes (Saint-Jacques), France`, value: `RNS - Rennes (Saint-Jacques), France` },
  { label: `ROB - Monrovia (Roberts Field), Liberia`, value: `ROB - Monrovia (Roberts Field), Liberia` },
  { label: `ROM - Rome (Metropolitan Area), Italie`, value: `ROM - Rome (Metropolitan Area), Italie` },
  { label: `RSC - Riga (Skulte), Lettonie`, value: `RSC - Riga (Skulte), Lettonie` },
  { label: `RSE - Sydney (Aurose Bay), Australie`, value: `RSE - Sydney (Aurose Bay), Australie` },
  { label: `RST-Rochester, Minnesota`, value: `RST-Rochester, Minnesota` },
  { label: `RSW - FORT MYERS`, value: `RSW - FORT MYERS` },
  { label: `RTB - Roatan, Honduras`, value: `RTB - Roatan, Honduras` },
  { label: `RTM - Rotterdam, Pays-Bas`, value: `RTM - Rotterdam, Pays-Bas` },
  { label: `RUH - Riyadh (King Khalid), Arabie Saoudite`, value: `RUH - Riyadh (King Khalid), Arabie Saoudite` },
  { label: `RUN - Saint-Denis (Gillot), Réunion`, value: `RUN - Saint-Denis (Gillot), Réunion` },
  { label: `RUY - Copan, Honduras`, value: `RUY - Copan, Honduras` },
  { label: `RVN - Rovaniemi, Finlande`, value: `RVN - Rovaniemi, Finlande` },
  { label: `RWP - Rawalpindi, Pakistan`, value: `RWP - Rawalpindi, Pakistan` },
  { label: `SAB - Saba, Antilles Néerlandaises`, value: `SAB - Saba, Antilles Néerlandaises` },
  { label: `SAH - Sana'a (El Rahaba), Yémen`, value: `SAH - Sana'a (El Rahaba), Yémen` },
  { label: `SAL - San Salvador (Internacional), Salvador`, value: `SAL - San Salvador (Internacional), Salvador` },
  { label: `SAN - San Diego`, value: `SAN - San Diego` },
  { label: `SAO - Sao Paulo (Metropolitan Area), Brésil`, value: `SAO - Sao Paulo (Metropolitan Area), Brésil` },
  { label: `SAP - San Pedro Sula (La Mesa), Honduras`, value: `SAP - San Pedro Sula (La Mesa), Honduras` },
  { label: `SAT - SAN ANTONIO TEXAS`, value: `SAT - SAN ANTONIO TEXAS` },
  { label: `SAW - Istanbul (S.Gokcen Internat.), Turquie`, value: `SAW - Istanbul (S.Gokcen Internat.), Turquie` },
  { label: `SAY - Siene, Italie`, value: `SAY - Siene, Italie` },
  { label: `SBH - Saint Barthelemy, Guadeloupe`, value: `SBH - Saint Barthelemy, Guadeloupe` },
  { label: `SBK - Saint Brieuc, France`, value: `SBK - Saint Brieuc, France` },
  { label: `SCL - Santiago de Chili (A. M. Benitez), Chili`, value: `SCL - Santiago de Chili (A. M. Benitez), Chili` },
  { label: `SCN - Saarbrucken (Ensheim), Allemagne`, value: `SCN - Saarbrucken (Ensheim), Allemagne` },
  { label: `SCQ - Santiago Compostela (Santiago), Espagne`, value: `SCQ - Santiago Compostela (Santiago), Espagne` },
  { label: `SCU - Santiago (Antonio Maceo), Cuba`, value: `SCU - Santiago (Antonio Maceo), Cuba` },
  { label: `SDF - Louisville Kentucky`, value: `SDF - Louisville Kentucky` },
  { label: `SDQ - Santo Domingo, République Dominicaine`, value: `SDQ - Santo Domingo, République Dominicaine` },
  { label: `SDU - Rio de Janeiro (Santos Dumont), Brésil`, value: `SDU - Rio de Janeiro (Santos Dumont), Brésil` },
  { label: `SDV - Tel Aviv, Israël`, value: `SDV - Tel Aviv, Israël` },
  { label: `SEA - Seattle-Tacoma International, USA`, value: `SEA - Seattle-Tacoma International, USA` },
  { label: `SEL - Séoul (Kimpo), Corée du Sud`, value: `SEL - Séoul (Kimpo), Corée du Sud` },
  { label: `SEZ - Mahé (Seychelles Int'l), Seychelles`, value: `SEZ - Mahé (Seychelles Int'l), Seychelles` },
  { label: `SFA - Sfax (Thyna), Tunisie`, value: `SFA - Sfax (Thyna), Tunisie` },
  { label: `SFB-Sanford, Orlando`, value: `SFB-Sanford, Orlando` },
  { label: `SFO - San Francisco Internat. Airport, USA`, value: `SFO - San Francisco Internat. Airport, USA` },
  { label: `SGL - Manila, Philippines`, value: `SGL - Manila, Philippines` },
  { label: `SGN - Ho Chi Minh (Tan Son Nhut), Viêtnam`, value: `SGN - Ho Chi Minh (Tan Son Nhut), Viêtnam` },
  { label: `SHA - Shanghai (Hongqiao), Chine`, value: `SHA - Shanghai (Hongqiao), Chine` },
  { label: `SHJ - Sharjah International Airport, EAU`, value: `SHJ - Sharjah International Airport, EAU` },
  { label: `SIA - Xian (Xiguan Airport), Chine`, value: `SIA - Xian (Xiguan Airport), Chine` },
  { label: `SID - Sal (Amilcar Cabral), Cap Vert`, value: `SID - Sal (Amilcar Cabral), Cap Vert` },
  { label: `SIG - San Juan (F. L. Ribas), Puerto Rico`, value: `SIG - San Juan (F. L. Ribas), Puerto Rico` },
  { label: `SII - Sidi Ifni, Maroc`, value: `SII - Sidi Ifni, Maroc` },
  { label: `SIN - Singapour (Changi Internati), Singapour`, value: `SIN - Singapour (Changi Internati), Singapour` },
  { label: `SIR - Sion (Sitten), Suisse`, value: `SIR - Sion (Sitten), Suisse` },
  { label: `SJC - Sanjose, Californie`, value: `SJC - Sanjose, Californie` },
  { label: `SJD - Los Cabos (S. J. del Cabo), Mexique`, value: `SJD - Los Cabos (S. J. del Cabo), Mexique` },
  { label: `SJJ - Sarajevo, Bosnie`, value: `SJJ - Sarajevo, Bosnie` },
  { label: `SJO - San Jose (J. Santamaria), Costa Rica`, value: `SJO - San Jose (J. Santamaria), Costa Rica` },
  { label: `SJU - San Juan (L. Munoz Marin), Puerto Rico`, value: `SJU - San Juan (L. Munoz Marin), Puerto Rico` },
  { label: `SJZ - Sao Jorge, Açores`, value: `SJZ - Sao Jorge, Açores` },
  { label: `SKD - Samarkand, Ouzbékistan`, value: `SKD - Samarkand, Ouzbékistan` },
  { label: `SKG - Thessalonique (Mikra), Grèce`, value: `SKG - Thessalonique (Mikra), Grèce` },
  { label: `SKI - Skikda, Algérie`, value: `SKI - Skikda, Algérie` },
  { label: `SKL - Isle of Skye, Angleterre`, value: `SKL - Isle of Skye, Angleterre` },
  { label: `SKP - Skopje, Macedoine`, value: `SKP - Skopje, Macedoine` },
  { label: `SKU - Skiros, Grèce`, value: `SKU - Skiros, Grèce` },
  { label: `SLC - Salt Lake City Internat. Airport, USA`, value: `SLC - Salt Lake City Internat. Airport, USA` },
  { label: `SLZ - Sao Luis, Brésil`, value: `SLZ - Sao Luis, Brésil` },
  { label: `SMS - Ste Marie, Madagascar`, value: `SMS - Ste Marie, Madagascar` },
  { label: `SNA - Orange County, Californie`, value: `SNA - Orange County, Californie` },
  { label: `SNE - Sao Nicolau, Cap Vert`, value: `SNE - Sao Nicolau, Cap Vert` },
  { label: `SNN - Shannon (Limerick), Irlande`, value: `SNN - Shannon (Limerick), Irlande` },
  { label: `SNU - ( Santa Clara, Cuba )`, value: `SNU - ( Santa Clara, Cuba )` },
  { label: `SOF - Sofia (Vrajdebna), Bulgarie`, value: `SOF - Sofia (Vrajdebna), Bulgarie` },
  { label: `SOU - Southampton (Eastleigh), Angleterre`, value: `SOU - Southampton (Eastleigh), Angleterre` },
  { label: `SPK - Saporro (Chitose), Japon`, value: `SPK - Saporro (Chitose), Japon` },
  { label: `SPL - Amsterdam (Schiphol), Pays-Bas`, value: `SPL - Amsterdam (Schiphol), Pays-Bas` },
  { label: `SPU - Split (Kastel), Croatie`, value: `SPU - Split (Kastel), Croatie` },
  { label: `SRE - Sucre (J. A. de Padilla), Bolivie`, value: `SRE - Sucre (J. A. de Padilla), Bolivie` },
  { label: `SRQ - Sarasota`, value: `SRQ - Sarasota` },
  { label: `SSA - Salvador (Dois de Julho), Brésil`, value: `SSA - Salvador (Dois de Julho), Brésil` },
  { label: `SSG - Malabo, Guinée Equatoriale`, value: `SSG - Malabo, Guinée Equatoriale` },
  { label: `SSH - Sharm El Sheikh (Ras Nasrani), Egypte`, value: `SSH - Sharm El Sheikh (Ras Nasrani), Egypte` },
  { label: `STL - St. Louis International Airport, USA`, value: `STL - St. Louis International Airport, USA` },
  { label: `STM - Santarem, Brésil`, value: `STM - Santarem, Brésil` },
  { label: `STN - Londres (Stansted), Angleterre`, value: `STN - Londres (Stansted), Angleterre` },
  { label: `STO - Stockholm (Metropolitan Area), Suède`, value: `STO - Stockholm (Metropolitan Area), Suède` },
  { label: `STR - Stuttgart (Echterdingen), Allemagne`, value: `STR - Stuttgart (Echterdingen), Allemagne` },
  { label: `SUB - Surabaya (Juanda), Indonésie`, value: `SUB - Surabaya (Juanda), Indonésie` },
  { label: `SUV - Suva (Nausori International), Fidji`, value: `SUV - Suva (Nausori International), Fidji` },
  { label: `SVG - Stavanger (Sola), Norvège`, value: `SVG - Stavanger (Sola), Norvège` },
  { label: `SVO - Moscou (Cheremetievo 2), Russie`, value: `SVO - Moscou (Cheremetievo 2), Russie` },
  { label: `SVQ - Seville (San Pablo), Espagne`, value: `SVQ - Seville (San Pablo), Espagne` },
  { label: `SWK - Milan (Segrate), Italie`, value: `SWK - Milan (Segrate), Italie` },
  { label: `SWP - Swakopmund, Namibie`, value: `SWP - Swakopmund, Namibie` },
  { label: `SXB - Strasbourg (Entzheim), France`, value: `SXB - Strasbourg (Entzheim), France` },
  { label: `SXF - Berlin (Schoenefeld), Allemagne`, value: `SXF - Berlin (Schoenefeld), Allemagne` },
  { label: `SXM - St. Maarten (Princ. Juliana), Ant. Néerl.`, value: `SXM - St. Maarten (Princ. Juliana), Ant. Néerl.` },
  { label: `SYD - Sydney (Kingsford Smith), Australie`, value: `SYD - Sydney (Kingsford Smith), Australie` },
  { label: `SYZ - Shiraz, Iran`, value: `SYZ - Shiraz, Iran` },
  { label: `SZG - Salzburg, Autriche`, value: `SZG - Salzburg, Autriche` },
  { label: `SZX - Shenzhen, Chine`, value: `SZX - Shenzhen, Chine` },
  { label: `TAB - Tobago (Scarborough), Trinidad et Tobago`, value: `TAB - Tobago (Scarborough), Trinidad et Tobago` },
  { label: `TAH - Tanna Island, Vanuatu`, value: `TAH - Tanna Island, Vanuatu` },
  { label: `TAS - Tashkent (Yuzhny), Ouzbékistan`, value: `TAS - Tashkent (Yuzhny), Ouzbékistan` },
  { label: `TBS - Tbilisi (Novoalexeyevka), Georgie`, value: `TBS - Tbilisi (Novoalexeyevka), Georgie` },
  { label: `TBZ - Tabriz, Iran`, value: `TBZ - Tabriz, Iran` },
  { label: `TCI - Ténérife, Canaries`, value: `TCI - Ténérife, Canaries` },
  { label: `TDD - Trinidad, Bolivie`, value: `TDD - Trinidad, Bolivie` },
  { label: `TER - Terceira (Aeroporto das Lajes), Açores`, value: `TER - Terceira (Aeroporto das Lajes), Açores` },
  { label: `TFN - Ténérife (Los Rodeos), Canaries`, value: `TFN - Ténérife (Los Rodeos), Canaries` },
  { label: `TFR - Tarbes, France`, value: `TFR - Tarbes, France` },
  { label: `TFS - Ténérife (Sur Reina Sofia), Canaries`, value: `TFS - Ténérife (Sur Reina Sofia), Canaries` },
  { label: `TGU - Tegucigalpa (Toncontin), Honduras`, value: `TGU - Tegucigalpa (Toncontin), Honduras` },
  { label: `THF - Berlin (Tempelhof), Allemagne`, value: `THF - Berlin (Tempelhof), Allemagne` },
  { label: `THR - Theran (Qualeh Morgeh Airport), Iran`, value: `THR - Theran (Qualeh Morgeh Airport), Iran` },
  { label: `TIA - Tirana, Albania`, value: `TIA - Tirana, Albania` },
  { label: `TIP - Tripoli (Idris), Libye`, value: `TIP - Tripoli (Idris), Libye` },
  { label: `TQO - Internacional de Tulum`, value: `TQO - Internacional de Tulum` },
  { label: `TKU - Turku, Finlande`, value: `TKU - Turku, Finlande` },
  { label: `TLC - Toluca (A. L. Mateos), Mexique`, value: `TLC - Toluca (A. L. Mateos), Mexique` },
  { label: `TLE - Tulear, Madagascar`, value: `TLE - Tulear, Madagascar` },
  { label: `TLL - Tallinn (Yulemiste), Estonie`, value: `TLL - Tallinn (Yulemiste), Estonie` },
  { label: `TLN - Toulon/Hyeres (Le Palyvestre), France`, value: `TLN - Toulon/Hyeres (Le Palyvestre), France` },
  { label: `TLS - Toulouse (Blagnac), France`, value: `TLS - Toulouse (Blagnac), France` },
  { label: `TLV - Tel Aviv (Ben Gurion), Israël`, value: `TLV - Tel Aviv (Ben Gurion), Israël` },
  { label: `TMB - Miami (Kendall-Tamiami), USA`, value: `TMB - Miami (Kendall-Tamiami), USA` },
  { label: `TMM - Tamatave, Madagascar`, value: `TMM - Tamatave, Madagascar` },
  { label: `TMP - Tampere (Pirkkala), Finlande`, value: `TMP - Tampere (Pirkkala), Finlande` },
  { label: `TMR - Tamanrasset, Algérie`, value: `TMR - Tamanrasset, Algérie` },
  { label: `TMS - Sao Tome, Sao Tome et Principe`, value: `TMS - Sao Tome, Sao Tome et Principe` },
  { label: `TNG - Tanger (Boukhalef), Maroc`, value: `TNG - Tanger (Boukhalef), Maroc` },
  { label: `TNR - Antananarivo (Ivato), Madagascar`, value: `TNR - Antananarivo (Ivato), Madagascar` },
  { label: `TNT - Miami (Dade-Collier), USA`, value: `TNT - Miami (Dade-Collier), USA` },
  { label: `TOD - Tioman, Malaisie`, value: `TOD - Tioman, Malaisie` },
  { label: `TOE - Tozeur (Nefta), Tunisie`, value: `TOE - Tozeur (Nefta), Tunisie` },
  { label: `TOM - Tombouctou, Mali`, value: `TOM - Tombouctou, Mali` },
  { label: `TPA - Tampa, Floride`, value: `TPA - Tampa, Floride` },
  { label: `TPE - Taipei (Chiang Kai Shek), Taiwan`, value: `TPE - Taipei (Chiang Kai Shek), Taiwan` },
  { label: `TRD - Trondheim (Vaernes), Norvège`, value: `TRD - Trondheim (Vaernes), Norvège` },
  { label: `TRG - Tauranga Nouvelle Zélande`, value: `TRG - Tauranga Nouvelle Zélande` },
  { label: `TRN - Turin (Caselle), Italie`, value: `TRN - Turin (Caselle), Italie` },
  { label: `TRS - Trieste (Ronchi dei Legionari), Italie`, value: `TRS - Trieste (Ronchi dei Legionari), Italie` },
  { label: `TRU - Trujillo, Pérou`, value: `TRU - Trujillo, Pérou` },
  { label: `TRV - Trivandrum (International), Inde`, value: `TRV - Trivandrum (International), Inde` },
  { label: `TSA - Taipei (Sung Shan), Taiwan`, value: `TSA - Taipei (Sung Shan), Taiwan` },
  { label: `TSF - Trévise (S. Angelo), Italie`, value: `TSF - Trévise (S. Angelo), Italie` },
  { label: `TSR - Timisoara (Giarmata), Roumanie`, value: `TSR - Timisoara (Giarmata), Roumanie` },
  { label: `TUF - Tours (Saint-Symphorien), France`, value: `TUF - Tours (Saint-Symphorien), France` },
  { label: `TUN - Tunis (Carthage Airport), Tunisie`, value: `TUN - Tunis (Carthage Airport), Tunisie` },
  { label: `TXL - Berlin (Tegel), Allemagne`, value: `TXL - Berlin (Tegel), Allemagne` },
  { label: `TYO - Tokyo (Metropolitan Area), Japon`, value: `TYO - Tokyo (Metropolitan Area), Japon` },
  { label: `TZA - Belize City (Municipal), Belize`, value: `TZA - Belize City (Municipal), Belize` },
  { label: `UAK - Narssarssuaq, Groenland`, value: `UAK - Narssarssuaq, Groenland` },
  { label: `UDR - Udaipur, Inde`, value: `UDR - Udaipur, Inde` },
  { label: `UET - Quetta, Pakistan`, value: `UET - Quetta, Pakistan` },
  { label: `UGN - Chicago (Waukegan Regional), USA`, value: `UGN - Chicago (Waukegan Regional), USA` },
  { label: `UII - Utila Island, Honduras`, value: `UII - Utila Island, Honduras` },
  { label: `UIK - Irkutsk, Russie`, value: `UIK - Irkutsk, Russie` },
  { label: `UIO - Quito (Mariscal Sucre), Equateur`, value: `UIO - Quito (Mariscal Sucre), Equateur` },
  { label: `UIP - Quimper (Pluguffan), France`, value: `UIP - Quimper (Pluguffan), France` },
  { label: `UKB - Kobe, Japon`, value: `UKB - Kobe, Japon` },
  { label: `UKY - Kyoto, Japon`, value: `UKY - Kyoto, Japon` },
  { label: `ULC - Santiago de Chili, Chili`, value: `ULC - Santiago de Chili, Chili` },
  { label: `ULN - Ulan Bator, Mongolia`, value: `ULN - Ulan Bator, Mongolia` },
  { label: `UPG - Ujung Pandang (Hasanuddin), Indonésie`, value: `UPG - Ujung Pandang (Hasanuddin), Indonésie` },
  { label: `URC - Urumqi (Diwopu), Chine`, value: `URC - Urumqi (Diwopu), Chine` },
  { label: `URO - Rouen, France`, value: `URO - Rouen, France` },
  { label: `USH - Ushuaia, Argentine`, value: `USH - Ushuaia, Argentine` },
  { label: `USM - Koh Samui, Thailand`, value: `USM - Koh Samui, Thailand` },
  { label: `UUD - Ulan Ude (Mukhino), Russie`, value: `UUD - Ulan Ude (Mukhino), Russie` },
  { label: `UVE - Ouvéa, Nlle Calédonie`, value: `UVE - Ouvéa, Nlle Calédonie` },
  { label: `UVF - Ste Lucie`, value: `UVF - Ste Lucie` },
  { label: `VAN - Van, Turquie`, value: `VAN - Van, Turquie` },
  { label: `VAP - Valparaiso, Chili`, value: `VAP - Valparaiso, Chili` },
  { label: `VAR - Varna, Bulgarie`, value: `VAR - Varna, Bulgarie` },
  { label: `VCE - Venise (Marco Polo), Italie`, value: `VCE - Venise (Marco Polo), Italie` },
  { label: `VCP - Sao Paulo/Campinas (Viracopos), Brésil`, value: `VCP - Sao Paulo/Campinas (Viracopos), Brésil` },
  { label: `VER - Veracruz (General H. Jara), Mexique`, value: `VER - Veracruz (General H. Jara), Mexique` },
  { label: `VFA - Victoria Falls, Zimbabwe`, value: `VFA - Victoria Falls, Zimbabwe` },
  { label: `VGO - Vigo, Espagne`, value: `VGO - Vigo, Espagne` },
  { label: `VIE - Vienne (Schwechat), Autriche`, value: `VIE - Vienne (Schwechat), Autriche` },
  { label: `VIT - Vitoria, Espagne`, value: `VIT - Vitoria, Espagne` },
  { label: `VKO - Moscow (Vnukovo), Russie`, value: `VKO - Moscow (Vnukovo), Russie` },
  { label: `VLC - Valencia, Espagne`, value: `VLC - Valencia, Espagne` },
  { label: `VLI - Port Vila (Bauerfield), Vanuatu`, value: `VLI - Port Vila (Bauerfield), Vanuatu` },
  { label: `VLL - Valladolid, Espagne`, value: `VLL - Valladolid, Espagne` },
  { label: `VNO - Vilnius, Lituanie`, value: `VNO - Vilnius, Lituanie` },
  { label: `VNS - Varanasi, Inde`, value: `VNS - Varanasi, Inde` },
  { label: `VNY - Los Angeles (Van Nuys Airport), USA`, value: `VNY - Los Angeles (Van Nuys Airport), USA` },
  { label: `VPS- Fort Walton Beach, Floride`, value: `VPS- Fort Walton Beach, Floride` },
  { label: `VRA - Varadero, Cuba`, value: `VRA - Varadero, Cuba` },
  { label: `VRN - Verone (Villafranca), Italie`, value: `VRN - Verone (Villafranca), Italie` },
  { label: `VTE - Vientiane (Wattay), Laos`, value: `VTE - Vientiane (Wattay), Laos` },
  { label: `VVI - Santa Cruz (Viru Viru Internat.), Bolivie`, value: `VVI - Santa Cruz (Viru Viru Internat.), Bolivie` },
  { label: `VVO - Vladivostok, Russie`, value: `VVO - Vladivostok, Russie` },
  { label: `VXE - Sao Vicente, Cap Vert`, value: `VXE - Sao Vicente, Cap Vert` },
  { label: `VXX - Mexico City (Sertel), Mexique`, value: `VXX - Mexico City (Sertel), Mexique` },
  { label: `WAS - Washington (Metropolitan Area), USA`, value: `WAS - Washington (Metropolitan Area), USA` },
  { label: `WAW - Varsovie (Okecie), Pologne`, value: `WAW - Varsovie (Okecie), Pologne` },
  { label: `WDH Windhoek (J. G. Strijdom Inter.), Namibia`, value: `WDH Windhoek (J. G. Strijdom Inter.), Namibia` },
  { label: `WFI - Fianarantsoa, Madagascar`, value: `WFI - Fianarantsoa, Madagascar` },
  { label: `WGL - Baltra Island, Galapagos, Equateur`, value: `WGL - Baltra Island, Galapagos, Equateur` },
  { label: `WHF - Wadi Halfa, Soudan`, value: `WHF - Wadi Halfa, Soudan` },
  { label: `WHP - Los Angeles (Whiteman Airport), USA`, value: `WHP - Los Angeles (Whiteman Airport), USA` },
  { label: `WIL - Nairobi (Wilson), Kenya`, value: `WIL - Nairobi (Wilson), Kenya` },
  { label: `WLG - Wellington, Nouvelle-Zélande`, value: `WLG - Wellington, Nouvelle-Zélande` },
  { label: `WLS - Wallis (Hihifo), Wallis et Futuna`, value: `WLS - Wallis (Hihifo), Wallis et Futuna` },
  { label: `WMR - Mananara, Madagascar`, value: `WMR - Mananara, Madagascar` },
  { label: `WNT - Hierro, Canaries`, value: `WNT - Hierro, Canaries` },
  { label: `WPU - Puerto Williams, Chili`, value: `WPU - Puerto Williams, Chili` },
  { label: `WRK - Dallas (White Rock Airport), USA`, value: `WRK - Dallas (White Rock Airport), USA` },
  { label: `WRO - Wroclaw, Pologne`, value: `WRO - Wroclaw, Pologne` },
  { label: `WVB - Walvis Bay, Namibia`, value: `WVB - Walvis Bay, Namibia` },
  { label: `WVK - Manakara, Madagascar`, value: `WVK - Manakara, Madagascar` },
  { label: `XFW - Hamburg-Finkenwerder, Allemagne`, value: `XFW - Hamburg-Finkenwerder, Allemagne` },
  { label: `XLS - Saint Louis, Sénégal`, value: `XLS - Saint Louis, Sénégal` },
  { label: `XLW - Bremen (Lemwerder), Allemagne`, value: `XLW - Bremen (Lemwerder), Allemagne` },
  { label: `XQP - Quepos, Costa Rica`, value: `XQP - Quepos, Costa Rica` },
  { label: `XXB - Manchester (Woodford), Angleterre`, value: `XXB - Manchester (Woodford), Angleterre` },
  { label: `XYD Gare de Lyon Part Dieu`, value: `XYD Gare de Lyon Part Dieu` },
  { label: `YAO - Yaounde (Nsimalen Internat.), Cameroun`, value: `YAO - Yaounde (Nsimalen Internat.), Cameroun` },
  { label: `YBC - Baie-Comeau, QC, Canada`, value: `YBC - Baie-Comeau, QC, Canada` },
  { label: `YBD - Vancouver (New Westminster), Canada`, value: `YBD - Vancouver (New Westminster), Canada` },
  { label: `YBG - Bagotville, Québec`, value: `YBG - Bagotville, Québec` },
  { label: `YDA - Dawson, Canada`, value: `YDA - Dawson, Canada` },
  { label: `YDF - DEER LAKE`, value: `YDF - DEER LAKE` },
  { label: `YDT - Vancouver (Boundary Bay), Canada`, value: `YDT - Vancouver (Boundary Bay), Canada` },
  { label: `YEA - Edmonton (Metropolitan Area), Canada`, value: `YEA - Edmonton (Metropolitan Area), Canada` },
  { label: `YED - Edmonton (Namao Field), Canada`, value: `YED - Edmonton (Namao Field), Canada` },
  { label: `YEG - Edmonton Internat. Airport, Canada`, value: `YEG - Edmonton Internat. Airport, Canada` },
  { label: `YGF - Cape Town (Youngsfield), Af. du Sud`, value: `YGF - Cape Town (Youngsfield), Af. du Sud` },
  { label: `YGK-Kingston, Ontario`, value: `YGK-Kingston, Ontario` },
  { label: `YFC - Fredericton International Airport`, value: `YFC - Fredericton International Airport` },
  { label: `YGR - Iles de la Madeleine, Canada`, value: `YGR - Iles de la Madeleine, Canada` },
  { label: `YHU - Montreal (St.-Hubert), Canada`, value: `YHU - Montreal (St.-Hubert), Canada` },
  { label: `YHZ - Halifax International Airport, Canada`, value: `YHZ - Halifax International Airport, Canada` },
  { label: `YIP - Detroit (Willow Run Airport), USA`, value: `YIP - Detroit (Willow Run Airport), USA` },
  { label: `YKZ - Toronto (Buttonville Airfield), Canada`, value: `YKZ - Toronto (Buttonville Airfield), Canada` },
  { label: `YLP - Mingan, Canada`, value: `YLP - Mingan, Canada` },
  { label: `YLW-Kelowna BC`, value: `YLW-Kelowna BC` },
  { label: `YMM - Fort Mcmurray, Alberta`, value: `YMM - Fort Mcmurray, Alberta` },
  { label: `YMQ - Montréal (région métropolitaine), Canada`, value: `YMQ - Montréal (région métropolitaine), Canada` },
  { label: `YMX - Montréal (Mirabel internat.), Canada`, value: `YMX - Montréal (Mirabel internat.), Canada` },
  { label: `YND - Hull - Ottawa (Gatineau Airport), Canada`, value: `YND - Hull - Ottawa (Gatineau Airport), Canada` },
  { label: `YOK - Yokohama, Japon`, value: `YOK - Yokohama, Japon` },
  { label: `YOW - Ottawa (Macdonald-Cartier), Canada`, value: `YOW - Ottawa (Macdonald-Cartier), Canada` },
  { label: `YQB - Québec, QC, Canada`, value: `YQB - Québec, QC, Canada` },
  { label: `YQM - Moncton`, value: `YQM - Moncton` },
  { label: `YQR - Saskatchewan, Canada`, value: `YQR - Saskatchewan, Canada` },
  { label: `YQT-Thunder Bay`, value: `YQT-Thunder Bay` },
  { label: `YQU - Grande Prairie`, value: `YQU - Grande Prairie` },
  { label: `YRO - Ottawa (Rockcliffe Airport), Canada`, value: `YRO - Ottawa (Rockcliffe Airport), Canada` },
  { label: `YRQ - Trois-Rivieres, Canada`, value: `YRQ - Trois-Rivieres, Canada` },
  { label: `YSX - Halifax (Shearwater Airport), Canada`, value: `YSX - Halifax (Shearwater Airport), Canada` },
  { label: `YTO - Toronto (Metropolitan Area), Canada`, value: `YTO - Toronto (Metropolitan Area), Canada` },
  { label: `YTZ - Toronto Island Airport, Canada`, value: `YTZ - Toronto Island Airport, Canada` },
  { label: `YUL - Montréal (P. E. Trudeau), Canada`, value: `YUL - Montréal (P. E. Trudeau), Canada` },
  { label: `YUY-Rouyn Noranda`, value: `YUY-Rouyn Noranda` },
  { label: `YVA - Moroni (Hahaya/Iconi), Comores`, value: `YVA - Moroni (Hahaya/Iconi), Comores` },
  { label: `YVO - Val d'or`, value: `YVO - Val d'or` },
  { label: `YVR - Vancouver Internat. Airport, Canada`, value: `YVR - Vancouver Internat. Airport, Canada` },
  { label: `YVZ - Sept-Îles`, value: `YVZ - Sept-Îles` },
  { label: `YWG - Winnipeg Internat. Airport, Canada`, value: `YWG - Winnipeg Internat. Airport, Canada` },
  { label: `YXC - Saskatoon`, value: `YXC - Saskatoon` },
  { label: `YXD - Edmonton Municipal Airport, Canada`, value: `YXD - Edmonton Municipal Airport, Canada` },
  { label: `YXE - SASKATOON`, value: `YXE - SASKATOON` },
  { label: `YYC - Calgary Internat. Airport, Canada`, value: `YYC - Calgary Internat. Airport, Canada` },
  { label: `YYJ - Victoria,  Colombie Britanique`, value: `YYJ - Victoria,  Colombie Britanique` },
  { label: `YYT-StJohn,TerreNeuve`, value: `YYT-StJohn,TerreNeuve` },
  { label: `YYZ - Toronto (L. B. Pearson Int'l), Canada`, value: `YYZ - Toronto (L. B. Pearson Int'l), Canada` },
  { label: `YZD - Toronto (Downsview), Canada`, value: `YZD - Toronto (Downsview), Canada` },
  { label: `YZV- Sept Iles. Canada`, value: `YZV- Sept Iles. Canada` },
  { label: `ZAD - Zadar, Croatie`, value: `ZAD - Zadar, Croatie` },
  { label: `ZAG - Zagreb (Pleso), Croatie`, value: `ZAG - Zagreb (Pleso), Croatie` },
  { label: `ZAM - Zamboanga International, Philippines`, value: `ZAM - Zamboanga International, Philippines` },
  { label: `ZAO - Cahors, France`, value: `ZAO - Cahors, France` },
  { label: `ZAZ - Saragosse, Espagne`, value: `ZAZ - Saragosse, Espagne` },
  { label: `ZDJ - Berne, Suisse`, value: `ZDJ - Berne, Suisse` },
  { label: `ZGC - Lanzhou (Zhongchuan), Chine`, value: `ZGC - Lanzhou (Zhongchuan), Chine` },
  { label: `ZIG - Ziguinchor, Sénégal`, value: `ZIG - Ziguinchor, Sénégal` },
  { label: `ZIH - Ixtapa, Mexique`, value: `ZIH - Ixtapa, Mexique` },
  { label: `ZLO-Manzanillo , Mexique`, value: `ZLO-Manzanillo , Mexique` },
  { label: `ZND - Zinder, Niger`, value: `ZND - Zinder, Niger` },
  { label: `ZNZ - Zanzibar, Tanzanie`, value: `ZNZ - Zanzibar, Tanzanie` },
  { label: `ZPE - Onasbruck, Allemagne`, value: `ZPE - Onasbruck, Allemagne` },
  { label: `ZRE - Rethymnon, Grèce`, value: `ZRE - Rethymnon, Grèce` },
  { label: `ZRH - Zurich (Zürich-Kloten), Suisse`, value: `ZRH - Zurich (Zürich-Kloten), Suisse` },
  { label: `ZSA - San Salvador,  Bahamas`, value: `ZSA - San Salvador,  Bahamas` },
  { label: `ZVK - Savannakhet, Laos`, value: `ZVK - Savannakhet, Laos` },
];

export const PRODUCT_TYPE = [
  {
    "label": "Annulation / Remboursement",
    "value": "Annulation / Remboursement",
    "taxe": "0",
    "tps": "0",
    "tvq": "0",
    "rabais": "0",
    "opc": "0",
    "id": 0
  },
  {
    "label": "Air Canada /Vol",
    "value": "Air Canada /Vol",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 1
  },
  {
    "label": "Air Transat",
    "value": "AT",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 2
  },
  {
    "label": "Alio",
    "value": "Alio",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 3
  },
  {
    "label": "Amerik Aventure ",
    "value": "Amerik Aventure ",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 4
  },
  {
    "label": "Amtrak",
    "value": "Amtrak",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 5
  },
  {
    "label": "Archaeological Paths",
    "value": "Archaeological Paths",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 6
  },
  {
    "label": "Atlantis Events",
    "value": "Atlantis Events",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 7
  },
  {
    "label": "Auto Europe",
    "value": "Auto Europe",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 8
  },
  {
    "label": "Autre",
    "value": "Autre",
    "taxe": "0",
    "tps": "1",
    "tvq": "1",
    "rabais": "1",
    "opc": "1",
    "id": 9
  },
  {
    "label": "Autre Assurance",
    "value": "Autre Assurance",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "0",
    "id": 10
  },
  {
    "label": "Autres",
    "value": "Autres",
    "taxe": "0",
    "tps": "0",
    "tvq": "0",
    "rabais": "0",
    "opc": "0",
    "id": 11
  },
  {
    "label": "Avalon Waterways",
    "value": "Avalon Waterways",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 12
  },
  {
    "label": "Avianet",
    "value": "Avianet",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 13
  },
  {
    "label": "Avianet/ Terratours",
    "value": "Avianet/ Terratours",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 14
  },
  {
    "label": "Avis",
    "value": "Avis",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 15
  },
  {
    "label": "Beltour",
    "value": "Beltour",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 16
  },
  {
    "label": "Boomerang Tours",
    "value": "Boomerang Tours",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 17
  },
  {
    "label": "Canandes Forfait",
    "value": "Canandes Forfait",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 18
  },
  {
    "label": "Canandes Vol",
    "value": "Canandes Vol",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 19
  },
  {
    "label": "CaribeSol Forfait",
    "value": "CaribeSol Forfait",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 20
  },
  {
    "label": "CaribeSol Vol",
    "value": "CaribeSol Vol",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 21
  },
  {
    "label": "Carte Postale",
    "value": "Carte Postale",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 22
  },
  {
    "label": "Cartes d'affaires",
    "value": "Cartes d'affaires",
    "taxe": "0",
    "tps": "1",
    "tvq": "1",
    "rabais": "1",
    "opc": "0",
    "id": 23
  },
  {
    "label": "Cassis",
    "value": "Cassis",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 24
  },
  {
    "label": "Celebritours",
    "value": "Celebritours",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 25
  },
  {
    "label": "Central Mountain Air",
    "value": "Central Mountain Air",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 26
  },
  {
    "label": "Certificat",
    "value": "Certificat",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "0",
    "id": 27
  },
  {
    "label": "CIT Canada",
    "value": "CIT Canada",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 28
  },
  {
    "label": "Club Med",
    "value": "Club Med",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 29
  },
  {
    "label": "Contiki",
    "value": "Contiki",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 30
  },
  {
    "label": "Contrat Echange",
    "value": "Contrat Echange",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "0",
    "opc": "0",
    "id": 31
  },
  {
    "label": "Cotisation AFL",
    "value": "Cotisation AFL",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "0",
    "id": 32
  },
  {
    "label": "Croisière Russie",
    "value": "Croisière Russie",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "0",
    "id": 33
  },
  {
    "label": "Croisières Divers",
    "value": "Croisières Divers",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 34
  },
  {
    "label": "Croix Bleue",
    "value": "Croix Bleue",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "0",
    "id": 35
  },
  {
    "label": "Cubana",
    "value": "Cubana",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 36
  },
  {
    "label": "Days Inn Opc",
    "value": "Days Inn Opc",
    "taxe": "1",
    "tps": "1",
    "tvq": "1",
    "rabais": "1",
    "opc": "1",
    "id": 37
  },
  {
    "label": "DaysInn",
    "value": "DaysInn",
    "taxe": "1",
    "tps": "1",
    "tvq": "1",
    "rabais": "1",
    "opc": "0",
    "id": 38
  },
  {
    "label": "Delta  Airlines",
    "value": "Delta  Airlines",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 39
  },
  {
    "label": "Disney",
    "value": "Disney",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 40
  },
  {
    "label": "Divers",
    "value": "Divers",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "0",
    "id": 41
  },
  {
    "label": "Divers",
    "value": "Divers",
    "taxe": "0",
    "tps": "0",
    "tvq": "0",
    "rabais": "0",
    "opc": "0",
    "id": 42
  },
  {
    "label": "Don à la fondation pour l'enfance",
    "value": "Don à la fondation pour l'enfance",
    "taxe": "0",
    "tps": "0",
    "tvq": "0",
    "rabais": "0",
    "opc": "0",
    "id": 43
  },
  {
    "label": "Dubai",
    "value": "Dubai",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "0",
    "id": 44
  },
  {
    "label": "Dubai à La Carte",
    "value": "Dubai à La Carte",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "0",
    "id": 45
  },
  {
    "label": "Dubai Croisière",
    "value": "Dubai Croisière",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "0",
    "id": 46
  },
  {
    "label": "Encore Cruises",
    "value": "Encore Cruises",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 47
  },
  {
    "label": "Esprit",
    "value": "Esprit",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 48
  },
  {
    "label": "Eurocar Vacances",
    "value": "Eurocar Vacances",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 49
  },
  {
    "label": "Europcar",
    "value": "Europcar",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 50
  },
  {
    "label": "Europe Auto",
    "value": "Europe Auto",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 51
  },
  {
    "label": "EvaCroisiere",
    "value": "EvaCroisiere",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 52
  },
  {
    "label": "Ex-Produit 1",
    "value": "Ex-Produit 1",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 53
  },
  {
    "label": "Ex-Produit 2",
    "value": "Ex-Produit 2",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 54
  },
  {
    "label": "Exotik Tours",
    "value": "Exotik Tours",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 55
  },
  {
    "label": "Expedia Hôtel",
    "value": "Expedia Hôtel",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 56
  },
  {
    "label": "Expedia Vol",
    "value": "Expedia Vol",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 57
  },
  {
    "label": "Expertours",
    "value": "Expertours",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 58
  },
  {
    "label": "Flextour",
    "value": "Flextour",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 59
  },
  {
    "label": "Forfait Divers",
    "value": "Forfait Divers",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 60
  },
  {
    "label": "Forfaits Las Vegas",
    "value": "Forfaits Las Vegas",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "0",
    "id": 61
  },
  {
    "label": "Formations Agts Externes",
    "value": "Formations Agts Externes",
    "taxe": "0",
    "tps": "1",
    "tvq": "1",
    "rabais": "0",
    "opc": "0",
    "id": 62
  },
  {
    "label": "Frais d'émission de billet(s)",
    "value": "Frais d'émission de billet(s)",
    "taxe": "0",
    "tps": "1",
    "tvq": "1",
    "rabais": "0",
    "opc": "0",
    "id": 63
  },
  {
    "label": "Frais de dossier",
    "value": "Frais de dossier",
    "taxe": "0",
    "tps": "1",
    "tvq": "1",
    "rabais": "0",
    "opc": "0",
    "id": 64
  },
  {
    "label": "Frais de dossier",
    "value": "Frais de dossier",
    "taxe": "0",
    "tps": "1",
    "tvq": "1",
    "rabais": "0",
    "opc": "1",
    "id": 65
  },
  {
    "label": "Frais Dossier",
    "value": "Frais Dossier",
    "taxe": "0",
    "tps": "1",
    "tvq": "1",
    "rabais": "0",
    "opc": "0",
    "id": 66
  },
  {
    "label": "FTO - Traitement de dossier",
    "value": "FTO - Traitement de dossier",
    "taxe": "0",
    "tps": "1",
    "tvq": "1",
    "rabais": "0",
    "opc": "0",
    "id": 67
  },
  {
    "label": "Fun Sun",
    "value": "Fun Sun",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 68
  },
  {
    "label": "Fun Sun Vacations",
    "value": "Fun Sun Vacations",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 69
  },
  {
    "label": "Gapventures",
    "value": "Gapventures",
    "taxe": "0",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 70
  },
  {
    "label": "Globus",
    "value": "Globus",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 71
  },
  {
    "label": "Gogo Woldwide Vacations",
    "value": "Gogo Woldwide Vacations",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 72
  },
  {
    "label": "Gr Voyages Quebec",
    "value": "Gr Voyages Quebec",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 73
  },
  {
    "label": "Grands Espaces Sarl",
    "value": "Grands Espaces Sarl",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 74
  },
  {
    "label": "Groupe Vacances",
    "value": "Groupe Vacances",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 75
  },
  {
    "label": "Groupe VIP",
    "value": "Groupe VIP",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 76
  },
  {
    "label": "Gtahotels.com",
    "value": "Gtahotels.com",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 77
  },
  {
    "label": "Hertz ",
    "value": "Hertz ",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 78
  },
  {
    "label": "Hi Tours",
    "value": "Hi Tours",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 79
  },
  {
    "label": "Holasun",
    "value": "Holasun",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 80
  },
  {
    "label": "Hôtel Divers",
    "value": "Hôtel Divers",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 81
  },
  {
    "label": "hotel.com",
    "value": "hotel.com",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 82
  },
  {
    "label": "Huntington",
    "value": "Huntington",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 83
  },
  {
    "label": "Incursion Voyages",
    "value": "Incursion Voyages",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 84
  },
  {
    "label": "Int Divers",
    "value": "Int Divers",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 85
  },
  {
    "label": "IntairAuto",
    "value": "IntairAuto",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 86
  },
  {
    "label": "Intersky",
    "value": "Intersky",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 87
  },
  {
    "label": "IntHôtel",
    "value": "IntHôtel",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 88
  },
  {
    "label": "IntVol",
    "value": "IntVol",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 89
  },
  {
    "label": "Jade Tours",
    "value": "Jade Tours",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 90
  },
  {
    "label": "Jevisite.ca",
    "value": "Jevisite.ca",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 91
  },
  {
    "label": "Le Groupe VIP",
    "value": "Le Groupe VIP",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 92
  },
  {
    "label": "Loc Appart",
    "value": "Loc Appart",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 93
  },
  {
    "label": "Lunettes",
    "value": "Lunettes",
    "taxe": "0",
    "tps": "1",
    "tvq": "1",
    "rabais": "0",
    "opc": "0",
    "id": 94
  },
  {
    "label": "Manuvie Ass Ann/Interruption",
    "value": "Manuvie Ass Ann/Interruption",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "0",
    "id": 95
  },
  {
    "label": "Manuvie Ass Covid",
    "value": "Manuvie Ass Covid",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "0",
    "id": 96
  },
  {
    "label": "Manuvie Ass For Médical",
    "value": "Manuvie Ass For Médical",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "0",
    "id": 97
  },
  {
    "label": "Manuvie Ass For Supérieur",
    "value": "Manuvie Ass For Supérieur",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "0",
    "id": 98
  },
  {
    "label": "Manuvie Ass Méd Classiques",
    "value": "Manuvie Ass Méd Classiques",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "0",
    "id": 99
  },
  {
    "label": "Martour",
    "value": "Martour",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 100
  },
  {
    "label": "Merika Tours",
    "value": "Merika Tours",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 101
  },
  {
    "label": "Merit Express",
    "value": "Merit Express",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 102
  },
  {
    "label": "National Car",
    "value": "National Car",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 103
  },
  {
    "label": "Netair",
    "value": "Netair",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 104
  },
  {
    "label": "New York en Autocar",
    "value": "New York en Autocar",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "0",
    "id": 105
  },
  {
    "label": "Norwegian Cruise Line",
    "value": "Norwegian Cruise Line",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 106
  },
  {
    "label": "Objectif Monde",
    "value": "Objectif Monde",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 107
  },
  {
    "label": "Océania Cruises",
    "value": "Océania Cruises",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 108
  },
  {
    "label": "Oceana Tours",
    "value": "Oceana Tours",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 109
  },
  {
    "label": "Pascan Aviation",
    "value": "Pascan Aviation",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 110
  },
  {
    "label": "Porter",
    "value": "Porter",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 111
  },
  {
    "label": "Pourboire Prépayé",
    "value": "Pourboire Prépayé",
    "taxe": "0",
    "tps": "0",
    "tvq": "0",
    "rabais": "0",
    "opc": "0",
    "id": 112
  },
  {
    "label": "Premium Tours",
    "value": "Premium Tours",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 113
  },
  {
    "label": "Princess",
    "value": "Princess",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 114
  },
  {
    "label": "Princess ",
    "value": "Princess ",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 115
  },
  {
    "label": "Provincial Airlines ",
    "value": "Provincial Airlines ",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 116
  },
  {
    "label": "Pullmantur",
    "value": "Pullmantur",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 117
  },
  {
    "label": "Quel Beau  Voyage",
    "value": "Quel Beau  Voyage",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 118
  },
  {
    "label": "Quimbaya International",
    "value": "Quimbaya International",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 119
  },
  {
    "label": "Rail Europe",
    "value": "Rail Europe",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 120
  },
  {
    "label": "Remboursement",
    "value": "Remboursement",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "0",
    "opc": "0",
    "id": 121
  },
  {
    "label": "Royal Scenic",
    "value": "Royal Scenic",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 122
  },
  {
    "label": "Sandals Forfait",
    "value": "Sandals Forfait",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 123
  },
  {
    "label": "Sél de Sièges",
    "value": "Sél de Sièges",
    "taxe": "0",
    "tps": "0",
    "tvq": "0",
    "rabais": "0",
    "opc": "0",
    "id": 124
  },
  {
    "label": "Selection Siege OPC",
    "value": "Selection Siege OPC",
    "taxe": "0",
    "tps": "0",
    "tvq": "0",
    "rabais": "0",
    "opc": "1",
    "id": 125
  },
  {
    "label": "Sign Forfait",
    "value": "Sign Forfait",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 126
  },
  {
    "label": "Simp Tours",
    "value": "Simp Tours",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 127
  },
  {
    "label": "Skylink",
    "value": "Skylink",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 128
  },
  {
    "label": "Skyroute",
    "value": "Skyroute",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 129
  },
  {
    "label": "Solazur",
    "value": "Solazur",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 130
  },
  {
    "label": "Spartan Tours",
    "value": "Spartan Tours",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 131
  },
  {
    "label": "Sport Vac",
    "value": "Sport Vac",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 132
  },
  {
    "label": "Sultana Tours",
    "value": "Sultana Tours",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 133
  },
  {
    "label": "Sunquest",
    "value": "Sunquest",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 134
  },
  {
    "label": "Sunwing  Ass",
    "value": "Sunwing  Ass",
    "taxe": "0",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "0",
    "id": 135
  },
  {
    "label": "Sunwing Forfait",
    "value": "Sunwing Forfait",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 136
  },
  {
    "label": "Sunwing Vol",
    "value": "Sunwing Vol",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 137
  },
  {
    "label": "Tour Hai",
    "value": "Tour Hai",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 138
  },
  {
    "label": "Tour Solazur",
    "value": "Tour Solazur",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 139
  },
  {
    "label": "Tourclub",
    "value": "Tourclub",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 140
  },
  {
    "label": "Tourico",
    "value": "Tourico",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 141
  },
  {
    "label": "Tours Amerique",
    "value": "Tours Amerique",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 142
  },
  {
    "label": "Tours Chanteclerc",
    "value": "Tours Chanteclerc",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 143
  },
  {
    "label": "Tours East",
    "value": "Tours East",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 144
  },
  {
    "label": "Trafalgar Tours",
    "value": "Trafalgar Tours",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 145
  },
  {
    "label": "Transat Forfait",
    "value": "Transat Forfait",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 146
  },
  {
    "label": "Transfert",
    "value": "Transfert",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 147
  },
  {
    "label": "Transfert",
    "value": "Transfert",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 148
  },
  {
    "label": "Travelport",
    "value": "Travelport",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 149
  },
  {
    "label": "Travel Brands",
    "value": "Travel Brands",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 150
  },
  {
    "label": "Unikatours",
    "value": "Unikatours",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 151
  },
  {
    "label": "Unikatours/Tours Jules Verne",
    "value": "Unikatours/Tours Jules Verne",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 152
  },
  {
    "label": "Unique Vacations",
    "value": "Unique Vacations",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 153
  },
  {
    "label": "United Airlines",
    "value": "United Airlines",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 154
  },
  {
    "label": "US Airways",
    "value": "US Airways",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 155
  },
  {
    "label": "Vac Air Canada",
    "value": "Vac Air Canada",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 156
  },
  {
    "label": "Vac Croisière",
    "value": "Vac Croisière",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 157
  },
  {
    "label": "Vac Préférence",
    "value": "Vac Préférence",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 158
  },
  {
    "label": "Vac Signature Vol",
    "value": "Vac Signature Vol",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 159
  },
  {
    "label": "Vac Summa",
    "value": "Vac Summa",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 160
  },
  {
    "label": "Vac WestJet",
    "value": "Vac WestJet",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 161
  },
  {
    "label": "Vacation Central",
    "value": "Vacation Central",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 162
  },
  {
    "label": "Valises et Accessoires",
    "value": "Valises et Accessoires",
    "taxe": "0",
    "tps": "1",
    "tvq": "1",
    "rabais": "0",
    "opc": "0",
    "id": 163
  },
  {
    "label": "Vat Auto",
    "value": "Vat Auto",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 164
  },
  {
    "label": "Vat Circuit",
    "value": "Vat Circuit",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 165
  },
  {
    "label": "Vat Croisière",
    "value": "Vat Croisière",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 166
  },
  {
    "label": "Vat Croisière",
    "value": "Vat Croisière",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 167
  },
  {
    "label": "Vat Divers",
    "value": "Vat Divers",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 168
  },
  {
    "label": "Vat Forfait",
    "value": "Vat Forfait",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 169
  },
  {
    "label": "Vat Hôtel",
    "value": "Vat Hôtel",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 170
  },
  {
    "label": "Vat Option Flex",
    "value": "Vat Option Flex",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "0",
    "opc": "1",
    "id": 171
  },
  {
    "label": "Vat Vol",
    "value": "Vat Vol",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 172
  },
  {
    "label": "Via Rail",
    "value": "Via Rail",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 173
  },
  {
    "label": "Viator",
    "value": "Viator",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 174
  },
  {
    "label": "Viking Cruises",
    "value": "Viking Cruises",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 175
  },
  {
    "label": "Vol Divers",
    "value": "Vol Divers",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 176
  },
  {
    "label": "VToursEtudiant",
    "value": "VToursEtudiant",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 177
  },
  {
    "label": "West Jet Forfait",
    "value": "West Jet Forfait",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 178
  },
  {
    "label": "Wing buddy",
    "value": "Wing buddy",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "1",
    "id": 179
  },
  {
    "label": "Manuvie forfait sans soins médicaux",
    "value": "Manuvie forfait sans soins médicaux",
    "taxe": "1",
    "tps": "0",
    "tvq": "0",
    "rabais": "1",
    "opc": "0",
    "id": 180
  }
]


export const REMARKS = [
  { label: `Acte de naissance et une pièce d'identité avec photo`, value: `Acte de naissance et une pièce d'identité avec photo` },
  { label: `Costa Rica: Taxe de sortie payable sur place de $32 US`, value: `Costa Rica: Taxe de sortie payable sur place de $32 US` },
  { label: `Cuba : Preuve d'assurance médicale requise pour entrer   à Cuba`, value: `Cuba : Preuve d'assurance médicale requise pour entrer   à Cuba` },
  { label: `Haiti Taxe touristique 10 $ US`, value: `Haiti Taxe touristique 10 $ US` },
  { label: `Le dépôt de ce forfait est 100% non Remboursable `, value: `Le dépôt de ce forfait est 100% non Remboursable ` },
  { label: `Nicaragua: Taxe de sortie payable sur place de $32 US`, value: `Nicaragua: Taxe de sortie payable sur place de $32 US` },
  { label: `Panama: Taxe de sortie payable sur place de $15 US`, value: `Panama: Taxe de sortie payable sur place de $15 US` },
  { label: `Passeport obligatoire`, value: `Passeport obligatoire` },
  { label: `Passeport obligatoire pour la République Dominicaine`, value: `Passeport obligatoire pour la République Dominicaine` },
  { label: `Passeport recommandé`, value: `Passeport recommandé` },
  { label: `Passeport valide, 3 mois après  votre date de  retour`, value: `Passeport valide, 3 mois après  votre date de  retour` },
  { label: `Passeport valide, 6 mois après votre date  de retour`, value: `Passeport valide, 6 mois après votre date  de retour` },
  { label: `Pourboire, Assurance, Boissons  et autres dépenses sur le bateau ne sont pas incluses`, value: `Pourboire, Assurance, Boissons  et autres dépenses sur le bateau ne sont pas incluses` },
  { label: `République Dominicaine Sunwing:Taxe de sortie payable sur place de $20 US et $10 US pour Carte Touristique`, value: `République Dominicaine Sunwing:Taxe de sortie payable sur place de $20 US et $10 US pour Carte Touristique` },
  { label: `San Andrès: Taxe de sortie payable sur place de $24 US`, value: `San Andrès: Taxe de sortie payable sur place de $24 US` },
  { label: `Transat / Frais  de 25 $ sur  le premier bagage enregistré sur tous  les vols en direction des Etats Unis `, value: `Transat / Frais  de 25 $ sur  le premier bagage enregistré sur tous  les vols en direction des Etats Unis ` },
  { label: `Sunwing : bagage de cabine payant : 25$`, value: `Sunwing : bagage de cabine payant : 25$` },
  { label: `Vérifier la validité des passeports de vos clients`, value: `Vérifier la validité des passeports de vos clients` },
  { label: `Sunwing : repas / snacks / boissons payants à bord (eau et café gratuit)`, value: `Sunwing : repas / snacks / boissons payants à bord (eau et café gratuit)` },
]

export const MODE = [
  { label: `Amex`, value: `Amex` },
  { label: `Autres`, value: `Autres` },
  { label: `Carte Cadeau RBC`, value: `Carte Cadeau RBC` },
  { label: `Chèque`, value: `Chèque` },
  { label: `Comptant`, value: `Comptant` },
  { label: `Crédit voyage`, value: `Crédit voyage` },
  { label: `Interac`, value: `Interac` },
  { label: `Master Card`, value: `Master Card` },
  { label: `Points RBC`, value: `Points RBC` },
  { label: `Uplift`, value: `Uplift` },
  { label: `Visa`, value: `Visa` },
]

export const CHOICES = [
  { label: `Oui`, value: `oui` },
  { label: `Non`, value: `non` },
]

export const OBJET = [
  { label: `Bon retour de voyage !`, value: `Bon retour de voyage !` },
  { label: `Facture à produire Client / dossier`, value: `Facture à produire Client / dossier` },
  { label: `Il est maintenant temps de faire les valises.. Bon Voyage !`, value: `Il est maintenant temps de faire les valises.. Bon Voyage !` },
  { label: `Important à lire`, value: `Important à lire` },
  { label: `Joyeuses Fêtes à vous et votre famille !`, value: `Joyeuses Fêtes à vous et votre famille !` },
  { label: `Joyeux anniversaire à vous !`, value: `Joyeux anniversaire à vous !` },
  { label: `Merci pour votre achat avec notre agence de voyages, voici votre facture.`, value: `Merci pour votre achat avec notre agence de voyages, voici votre facture.` },
  { label: `Pour votre information`, value: `Pour votre information` },
]

export const TRAVEL_SUPPLIER = [
  { label: `Canadan North`, value: `5T` },
  { label: `INSEL AIR`, value: `7I` },
  { label: `American Airline`, value: `AA` },
  { label: `Air Canada`, value: `AC` },
  { label: `Air India`, value: `Air India` },
  { label: `RESERVATIONS SEULEMENT CAD`, value: `AC1` },
  { label: `RESERVATIONS SEULEMENT CAD`, value: `AC2` },
  { label: `RESERVATIONS SEULEMENT CAD`, value: `AC3` },
  { label: `RESERVATIONS SEULEMENT CAD`, value: `AC4` },
  { label: `Accent Voyages`, value: `ACC` },
  { label: `Air Corsica`, value: `ACO` },
  { label: `CHATEAU DE L'AEROPORT MIRABEL`, value: `AER` },
  { label: `Air France`, value: `AF` },
  { label: `Autobus Gallnd Ltee`, value: `AGA` },
  { label: `Agencia Global`, value: `AGC` },
  { label: `Abotel Hotel Paris`, value: `AHP` },
  { label: `Air Colombus`, value: `AIR` },
  { label: `AIRLINERS`, value: `AIRL` },
  { label: `Alamo`, value: `ALA` },
  { label: `ALBA TOURS`, value: `ALB` },
  { label: `Allegiant Air`, value: `ALE` },
  { label: `Air Algerie`, value: `ALG` },
  { label: `Alio Tours`, value: `ALI` },
  { label: `ALOHA TOURS`, value: `ALO` },
  { label: `Allo Destination Inc`, value: `ALODEST` },
  { label: `All Nippon Airways`, value: `NH` },
  { label: `ALTISKI`, value: `ALT` },
  { label: `AEROMEXICO`, value: `AM-` },
  { label: `Amahuaca DMC & Events`, value: `AMADMC` },
  { label: `Americanada (montréal)`, value: `AME` },
  { label: `Amerik Aventure`, value: `AMERIK` },
  { label: `Voyages AML`, value: `AML` },
  { label: `Amtrak`, value: `AMT` },
  { label: `AEROLINEAS ARGENTINA`, value: `AR` },
  { label: `VIATOR- Arc MarketPlace`, value: `ARC` },
  { label: `Aegean Airlines`, value: `Aegean Airlines` },
  { label: `Artistours`, value: `ART` },
  { label: `AUBERGE ST ANTOINE`, value: `ASA` },
  { label: `ASIA PACIFIC TOURS`, value: `ASI` },
  { label: `Manuvie`, value: `ASSMAN` },
  { label: `Royal Air Maroc`, value: `AT` },
  { label: `Atlantis Events`, value: `ATL` },
  { label: `Vacances Atrium Inc.`, value: `ATR` },
  { label: `Atypika Tours`, value: `ATY` },
  { label: `AVIANCA `, value: `AVIANCA ` },
  { label: `RESERVATIONS SEULEMENT USD`, value: `AU1` },
  { label: `RESERVATIONS SEULEMENT USD`, value: `AU2` },
  { label: `RESERVATIONS SEULEMENT USD`, value: `AU3` },
  { label: `RESERVATIONS SEULEMENT USD`, value: `AU4` },
  { label: `Auberge Godefroy`, value: `AUB` },
  { label: `Ne Plus Utiliser Prendre Nol`, value: `AUR` },
  { label: `Auto Europe`, value: `AUT` },
  { label: `Leblanc Location d'autos`, value: `AUTL` },
  { label: `Avianet`, value: `AVA` },
  { label: `Amerik Aventure Inc.`, value: `AVE` },
  { label: `Les Aventures Éoliennes Inc`, value: `AVEO` },
  { label: `AVENTURES INUIT`, value: `AVI` },
  { label: `Aviatours`, value: `AVIA` },
  { label: `Avis Car Inc.`, value: `AVS` },
  { label: `ALITALIA`, value: `AZ` },
  { label: `Bearskin Airlines`, value: `BAI` },
  { label: `BAR HARBOUR INN`, value: `BAR` },
  { label: `Brandon Air Shuttle`, value: `BAS` },
  { label: `BRITISH AIRWAYS VACANCES`, value: `BAV` },
  { label: `BRITISH MIDLAND`, value: `BD` },
  { label: `BRUSSELS  AIRLINES `, value: `SN` },
  { label: `Bearskin Airlines`, value: `BE` },
  { label: `THE BEACHCOMBER CONDOMINIUMS`, value: `BEA` },
  { label: `Beltour`, value: `BEL` },
  { label: `BOB'S V.I.P. GOLF TOUR`, value: `BGT` },
  { label: `Boomerang Tours`, value: `BMR` },
  { label: `Voyages Boca`, value: `BOCA` },
  { label: `Bonanza (Vacances)`, value: `BON` },
  { label: `Bonus grossistes`, value: `BONUS` },
  { label: `BOOMERANG TOURS`, value: `BOO` },
  { label: `BRITISH AIRWAYS`, value: `BRI` },
  { label: `BRITRAIL TRAVEL INTERNATIONAL`, value: `BRT` },
  { label: `B.S.P. Canada`, value: `BSP` },
  { label: `BUDGET RENT-A-CAR`, value: `BUD` },
  { label: `Bwia West Indies Airways`, value: `BW` },
  { label: `Air China International`, value: `CA` },
  { label: `CACF TOURS`, value: `CAC` },
  { label: `Canjet Airlines`, value: `CAJ` },
  { label: `Canandes Int'l Tours Inc.`, value: `CAN` },
  { label: `Voyages Cap Aventures`, value: `CAP` },
  { label: `Caribe Sol`, value: `CAR` },
  { label: `Carnival Cruise Lines`, value: `CCL` },
  { label: `Centre Des Congres Tremblant`, value: `CCT` },
  { label: `Celibrity Cruise`, value: `CEL` },
  { label: `Central Mountain Air`, value: `CEM` },
  { label: `Vacation Central`, value: `CEN` },
  { label: `Croisières Franco-Fun`, value: `CFR` },
  { label: `Tours Chanteclerc`, value: `CHA` },
  { label: `LE CHATEAU MONTEBELLO`, value: `CHM` },
  { label: `HOTEL LE CHANTECLERC`, value: `CHT` },
  { label: `CIT CANADA`, value: `CIT` },
  { label: `Club Med`, value: `CLU` },
  { label: `Canadian North`, value: `CMM` },
  { label: `CONTINENTAL AIRLINES`, value: `CO-` },
  { label: `Cosmos`, value: `COM` },
  { label: `Consultour (Montréal)`, value: `CON` },
  { label: `Conquest Tours`, value: `COQ` },
  { label: `Corsair`, value: `CORSAIR` },
  { label: `Costa Cruise Lines`, value: `COSTA` },
  { label: `CHATEAU WHISTLER RESORT`, value: `CPH` },
  { label: `CALIFORNIA PARLOR CAR TOURS`, value: `CPT` },
  { label: `Crown Plaza Resort & Golf`, value: `CRO` },
  { label: `Croix Bleue`, value: `CROASS` },
  { label: `Croisieurope`, value: `CROI` },
  { label: `Croix Bleue Assurance`, value: `CRX` },
  { label: `CRYSTAL CRUISE`, value: `CRY` },
  { label: `CLUBTOUR - SAT`, value: `CSA` },
  { label: `Tours Chanteclerc`, value: `CTC` },
  { label: `Contiki`, value: `CTK` },
  { label: `Groupe Ctma`, value: `CTM` },
  { label: `ClubTour-Sat`, value: `CTS` },
  { label: `Cubana Airlines`, value: `CU` },
  { label: `Cubacan Tours`, value: `CUB` },
  { label: `Cunard Line`, value: `CUN` },
  { label: `TOURS CURE-VAC`, value: `CUR` },
  { label: `Circuit Vietnam Agathe Bui`, value: `CVAB` },
  { label: `Carlson Wagonlit Gaby`, value: `CWT` },
  { label: `CATHAY PACIFIC`, value: `CX` },
  { label: `DAYS INN QUEBEC`, value: `DAY` },
  { label: `DAYS INN Montreal`, value: `DAYMTL` },
  { label: `Dc Planning Inc.`, value: `DCP` },
  { label: `DESHAIES`, value: `DEH` },
  { label: `Der Destination Europe`, value: `DER` },
  { label: `DESJARDINS ASSURANCE-VOYAGE`, value: `DES` },
  { label: `Direct Air`, value: `DIRECT` },
  { label: `Delta Vol`, value: `DL` },
  { label: `fondation pour l'enfance`, value: `DON` },
  { label: `The New Driftwood Resort Motel`, value: `DRI` },
  { label: `D-tour Marketing`, value: `DTM` },
  { label: `Easy Jet`, value: `EASY` },
  { label: `Écomertours Nord-sud Inc.`, value: `ECO` },
  { label: `Ekilib`, value: `EKILIB` },
  { label: `ELIVA TOURS`, value: `ELI` },
  { label: `Ethiopian Airline`, value: `ET` },
  { label: `ENCORE CRUISES`, value: `ENC` },
  { label: `ESCAPADE INTERNATIONAL`, value: `ESC` },
  { label: `Escapade à Prix Fous`, value: `ESCAPADE` },
  { label: `Esprit (vacances)`, value: `ESP` },
  { label: `Vacances Tour Est`, value: `EST` },
  { label: `Eurostar`, value: `EU` },
  { label: `Eurocar Tt`, value: `EUC` },
  { label: `Europ Auto Vacances`, value: `EUR` },
  { label: `EVASION TOURS`, value: `EVA` },
  { label: `Expertours`, value: `EXP` },
  { label: `EXPEDIA`, value: `EXPE` },
  { label: `Exotik Tours`, value: `EXT` },
  { label: `Fairmont Tremblant`, value: `FAI` },
  { label: `FAR HILLS INN`, value: `FAR` },
  { label: `FARELINK`, value: `FARELINK` },
  { label: `FESTIVAL TOURS`, value: `FES` },
  { label: `Flextour`, value: `FLE` },
  { label: `LE FLORES`, value: `FLO` },
  { label: `Congres Fmoq`, value: `FMOQ` },
  { label: `Congres Fmoq`, value: `FMOQPRAGUE` },
  { label: `Fmoq Pré-congrès`, value: `FMOQSAPA` },
  { label: `Forfaiterie`, value: `FORFAITERI` },
  { label: `France Passion Plaisance`, value: `FPP` },
  { label: `Frais De Dossier`, value: `FTO` },
  { label: `Fun Sun Vacations`, value: `FUN` },
  { label: `Voyages Gaby`, value: `GAB` },
  { label: `Voyages Gaby`, value: `GAB1` },
  { label: `Gala Travels`, value: `GAL` },
  { label: `Groupe Voyage Gama Innovation`, value: `GAMA` },
  { label: `Gadventures`, value: `GAP` },
  { label: `Groupe Autocar Jeannois inc.`, value: `GAU` },
  { label: `GESCAN INTERNATIONAL S.A.R.L.`, value: `GES` },
  { label: `Gibiers De La Diable`, value: `GID` },
  { label: `GLOBUS CANADA`, value: `GLO` },
  { label: `Groupe Globe Expert`, value: `GLOBE` },
  { label: `Go Travel Direct`, value: `GO` },
  { label: `GODUBAI`, value: `GODUBAI` },
  { label: `Gogo Worlwide Vacations Canada`, value: `GOGO` },
  { label: `Golf Holidays`, value: `GOL` },
  { label: `Goway Travel`, value: `GOWAY` },
  { label: `Groupe Phaneuf`, value: `GPH` },
  { label: `Grands Espaces Sarl`, value: `GRA` },
  { label: `Gray Line New York Sightseeing`, value: `GRAY` },
  { label: `Groupe De Marie-paule Vaudry`, value: `GROUPE` },
  { label: `Groupe Voyage Quebec`, value: `GROUPE VQ` },
  { label: `Gryphon Golf/spa`, value: `GRY` },
  { label: `GLOBAL TOURISME`, value: `GT` },
  { label: `Gtahotels.com`, value: `GTA` },
  { label: `Groupe Voyages Québec`, value: `GVQ` },
  { label: `Hawkair Aviation Services Ltd.`, value: `HA` },
  { label: `Haiti Express`, value: `HAI` },
  { label: `HANH TRAVEL`, value: `HANH` },
  { label: `LES HOTELS ANCHORAGE SIRETO SA`, value: `HAS` },
  { label: `Reservations Seulement Cad`, value: `HC1` },
  { label: `RESERVATIONS SEULEMENT CAD`, value: `HC2` },
  { label: `RESERVATIONS SEULEMENT CAD`, value: `HC3` },
  { label: `RESERVATIONS SEULEMENT CAD`, value: `HC4` },
  { label: `HERTZ CANADA`, value: `HER` },
  { label: `HOTEL ESTEREL`, value: `HES` },
  { label: `Hi Tours Inc`, value: `HIT` },
  { label: `CENTRAL HOLIDAYS`, value: `HOL` },
  { label: `Hola Sun`, value: `HOLA` },
  { label: `Helo Maroc`, value: `HELO` },
  { label: `Holland America Cruise Line`, value: `HOLLAND` },
  { label: `Hotel Hilton Dorval`, value: `HOT` },
  { label: `Hotel.com`, value: `HOTCOM` },
  { label: `RESERVATIONS SEULEMENT USD`, value: `HU1` },
  { label: `RESERVATIONS SEULEMENT USD`, value: `HU2` },
  { label: `RESERVATIONS SEULEMENT USD`, value: `HU3` },
  { label: `RESERVATIONS SEULEMENT USD`, value: `HU4` },
  { label: `Regent Seven Seas Cruises`, value: `Regent Seven Seas Cruises` },
  { label: `Huntington`, value: `HUN` },
  { label: `Huberte Vienneau`, value: `HV` },
  { label: `IBER-SOL`, value: `IBE` },
  { label: `Iberia`, value: `Iberia`},
  { label: `Icelandair`, value: `Icelandair` },
  { label: `INTER CONTINENETAL`, value: `ICO` },
  { label: `IMAGINE QUEBEC`, value: `IMQ` },
  { label: `Incursion Voyages`, value: `INC` },
  { label: `INSIGHT INTERNATIONAL TOURS`, value: `INS` },
  { label: `Intair Transit`, value: `INT` },
  { label: `Intersky Inc.`, value: `INTS` },
  { label: `Intair Vacances`, value: `INV` },
  { label: `Tours Jules Verne`, value: `INVTJV` },
  { label: `INTERMEZZO ITALIATOUR`, value: `ITA` },
  { label: `VOYAGES INTERSKY`, value: `ITSKY` },
  { label: `Jade Tours`, value: `JAD` },
  { label: `Jaimonvoyage`, value: `JAIMON` },
  { label: `JAPAN AIR LINES`, value: `JAL` },
  { label: `Jet Blue`, value: `JBL` },
  { label: `Jetsgo`, value: `JET` },
  { label: `JEVISITE.CA`, value: `JEVISITE` },
  { label: `JOHN INGLE INSURANCE`, value: `JIN` },
  { label: `Johanne Lamer`, value: `JL` },
  { label: `J'ai mon tour.com`, value: `JMT` },
  { label: `Objectif Monde`, value: `JOL` },
  { label: `Justnets.ca`, value: `JUS` },
  { label: `BEARSKIN AIRLINES`, value: `JV` },
  { label: `KINTETSU INTERNATIONAL EXPRESS`, value: `KIE` },
  { label: `Merika Tours`, value: `KIL` },
  { label: `Voyages La Quebecoise`, value: `LAQ` },
  { label: `LAWSON'S TOURS`, value: `LAT` },
  { label: `Lise Bolduc`, value: `LB` },
  { label: `THE LEADING HOTEL`, value: `LEA` },
  { label: `ASSURANCE VOYAGEUR`, value: `LLO` },
  { label: `Location Multi-fetes Inc.`, value: `LMF` },
  { label: `The Lodging Company`, value: `LOG` },
  { label: `LACSA AIRLINES`, value: `LR` },
  { label: `M-H TOURS`, value: `M-H` },
  { label: `Magnum Tours`, value: `MAG` },
  { label: `Tours Maison`, value: `MAI` },
  { label: `MANHATTAN TOURS`, value: `MAN` },
  { label: `Marketing Quebec`, value: `MAQ` },
  { label: `MANOIR RICHELIEU`, value: `MAR` },
  { label: `Martour`, value: `MARTOUR` },
  { label: `Vacances Mastro`, value: `MAS` },
  { label: `MANOIR VICTORIA`, value: `MAV` },
  { label: `Alain Malric`, value: `MAVERICK` },
  { label: `MIKE BALL DIVE EXPEDITIONS`, value: `MBD` },
  { label: `Manoir Des Sables`, value: `MDS` },
  { label: `Vacances Megatour Holiday`, value: `MEG` },
  { label: `Merit Express`, value: `MER` },
  { label: `Magik Tours`, value: `MGK` },
  { label: `Congres Mgy`, value: `MGY` },
  { label: `Vacances Mimosa Inc.`, value: `MIM` },
  { label: `Air Mauritius`, value: `MK` },
  { label: `Marie Laure Brousseau`, value: `MLU` },
  { label: `MSCBOOK`, value: `MSCBOOK` },
  { label: `MANOIR SAINT-SAUVEUR`, value: `MSS` },
  { label: `MULTI VACANCES INC.`, value: `MUL` },
  { label: `Mundo Fx`, value: `MUN` },
  { label: `Munditour`, value: `Munditour` },
  { label: `MEXICANA`, value: `MX` },
  { label: `Navtours`, value: `NAV` },
  { label: `Norwegian Cruise Line`, value: `NCL` },
  { label: `Néo Tours (vacances)`, value: `NEO` },
  { label: `Netair`, value: `NET` },
  { label: `Nikka Tours`, value: `NIK` },
  { label: `Nolitours`, value: `NOL` },
  { label: `Netfaresonline`, value: `NOLINE` },
  { label: `Tours Mont-royal`, value: `NOU` },
  { label: `Nathalie Tremblay`, value: `NT` },
  { label: `Northwest Airlines`, value: `NW` },
  { label: `Air New Zealand`, value: `NZ` },
  { label: `Lufthansa `, value: `Lufthansa ` },
  { label: `OBEROI CRUISE`, value: `OBE` },
  { label: `OCEANAUTES`, value: `OCE` },
  { label: `Oceana Tours`, value: `OCEANA` },
  { label: `Oceania Cruises`, value: `OCEANIA` },
  { label: `Protection Du Consommateur`, value: `OPC` },
  { label: `OPTIMA TOURS DU CANADA`, value: `OPT` },
  { label: `Protection Du Consommateur`, value: `OPU` },
  { label: `Austrian  Airlines`, value: `OS` },
  { label: `pan express travel & tours`, value: `PAN` },
  { label: `PAPILLON GROUP`, value: `PAPILLON` },
  { label: `Voyages Paradis`, value: `PAR` },
  { label: `Paradores`, value: `PARADOR` },
  { label: `Pascan Aviation Inc.`, value: `PAS` },
  { label: `Vacances Canada 3000`, value: `PAX` },
  { label: `Provincial Airlines`, value: `PB` },
  { label: `Princess Cruises`, value: `PC` },
  { label: `Pierre Gravel International`, value: `PGI` },
  { label: `Phoenix Tours`, value: `PHO` },
  { label: `PLANET TOURS`, value: `PLT` },
  { label: `Rbc Points`, value: `POINTS` },
  { label: `Croisières Ponant`, value: `PONANT` },
  { label: `Porter Airlines`, value: `POR` },
  { label: `Porter Escapes`, value: `PORE` },
  { label: `POSEIDON TRIAENA`, value: `POS` },
  { label: `PHLIPPINE AIRLINES`, value: `PR` },
  { label: `Vacances Préférence`, value: `PRE` },
  { label: `Pregotours`, value: `PRG` },
  { label: `Premium Tours`, value: `PRM` },
  { label: `PARK SUNSET HOTEL`, value: `PSH` },
  { label: `Petit Tours Jules Verne`, value: `PTJV` },
  { label: `Pullmantur`, value: `PUL` },
  { label: `POINT DE VUE CANADA`, value: `PVC` },
  { label: `Pauline Vallée`, value: `PVE` },
  { label: `Qatar Airways`, value: `QATAR` },
  { label: `QUEL BEAU VOYAGES`, value: `QBV` },
  { label: `ROYAL AVIATION`, value: `QN` },
  { label: `Quebecair Express`, value: `QUE` },
  { label: `Rail Europe`, value: `RAI` },
  { label: `Réservations Seulement Cad`, value: `REC` },
  { label: `Regent seven seas cruise`, value: `Regent seven seas cruise` },
  { label: `Regent Seven Sea Cruises`, value: `REG` },
  { label: `Old Republic Canada`, value: `REL` },
  { label: `RENAULT CANADA`, value: `REN` },
  { label: `REVATOURS`, value: `RET` },
  { label: `Rêvatours`, value: `REV` },
  { label: `Richard Et Helene`, value: `RMH` },
  { label: `Royal Caribbean Cruise Line`, value: `ROC` },
  { label: `ROCKY MOUNTAINEER`, value: `ROCK` },
  { label: `Royal Scenic`, value: `ROY` },
  { label: `RSVP Vacations`, value: `RSVP` },
  { label: `Multitrav`, value: `RVA` },
  { label: `Sagres Tours`, value: `SAG` },
  { label: `Solazur`, value: `SAZ` },
  { label: `Solbec Tours`, value: `SBT` },
  { label: `Scenic Tours`, value: `SCE` },
  { label: `Super Club Vancances Ste-eusta`, value: `SCV` },
  { label: `Seabourn`, value: `SEA` },
  { label: `Sélection De Siège`, value: `SEAT` },
  { label: `Securiglobe`, value: `SEC` },
  { label: `Selim Tours`, value: `SEL` },
  { label: `Shangrila Tours`, value: `SHA` },
  { label: `SHERATON LAVAL`, value: `SHE` },
  { label: `Simpatico Tours`, value: `SIM` },
  { label: `Voyzant`, value: `SKRT` },
  { label: `Skylink Voyages`, value: `SKY` },
  { label: `Skylawn Travel`, value: `SKYLAWN` },
  { label: `Skyroute`, value: `SKYROU` },
  { label: `Skyway tours`, value: `SKYWAY` },
  { label: `Skylink Travel Inc. Yyz`, value: `SKYYYZ` },
  { label: `Sky express `, value: `JQ` },
  { label: `Swissair`, value: `Swissair` },
  { label: `SNCF`, value: `SNC` },
  { label: `SOFICOMEX & ASIE PACIFIQUE`, value: `SOF` },
  { label: `Vacances Signature`, value: `SOL` },
  { label: `Spartan Tours`, value: `SPA` },
  { label: `SPHINX TOURS`, value: `SPH` },
  { label: `Sportsmania`, value: `SPO` },
  { label: `Sport Vac`, value: `SPORVAC` },
  { label: `SilverSea`, value: `SSEA` },
  { label: `Standard Tours`, value: `STA` },
  { label: `Starlink Aviation`, value: `STAR` },
  { label: `Suntransferts.com`, value: `STR` },
  { label: `AEROFLOT`, value: `SU-` },
  { label: `Sunvak Tours`, value: `SUK` },
  { label: `Sultana Tours`, value: `SUL` },
  { label: `Vacances Summa`, value: `SUMFOR` },
  { label: `Vacances Sunwing`, value: `SUN` },
  { label: `Vacances Sunquest`, value: `SUQ` },
  { label: `Suvara Canada`, value: `SUV` },
  { label: `Tours Amérimonde`, value: `TAM` },
  { label: `Tours Amerique`, value: `TAME` },
  { label: `TOURS DUFOUR`, value: `TDU` },
  { label: `Tour East Holidays Canada Inc.`, value: `TEH` },
  { label: `Terratours`, value: `TER` },
  { label: `Trafalgar Tours`, value: `TFG` },
  { label: `Trafalgar Tours`, value: `TFR` },
  { label: `Tilden`, value: `TIL` },
  { label: `Timberwolf Tours Ltd`, value: `TIM` },
  { label: `Titan Tours`, value: `TIT` },
  { label: `Tours Jules Verne`, value: `TJV` },
  { label: `Ticketmaster`, value: `TKT` },
  { label: `Tours Maison`, value: `TMA` },
  { label: `Tours Monde Latin`, value: `TML` },
  { label: `TOURS NOUVELLE-ASIE`, value: `TNA` },
  { label: `VOYAGES TOKYO LTEE`, value: `TOK` },
  { label: `Tourika`, value: `TOR` },
  { label: `Total Vacations`, value: `TOT` },
  { label: `Tourclub`, value: `TOU` },
  { label: `Tourico Holidays`, value: `TOURICO` },
  { label: `Tours Hai International`, value: `TOURS` },
  { label: `Tunisair`, value: `TU` },
  { label: `Turkish airline`, value: `TK` },
  { label: `TRAVELINE`, value: `TRA` },
  { label: `Trans Andes`, value: `TRAN` },
  { label: `Programme tranquillité`, value: `TRANQ` },
  { label: `Transat Découverte`, value: `TRD` },
  { label: `Prendre Vat`, value: `TRF` },
  { label: `Trip Las Vegas`, value: `TRIP` },
  { label: `Tours Cure Vac`, value: `TRS` },
  { label: `Air Transat`, value: `TS` },
  { label: `The Tee Time Shop`, value: `TTTS` },
  { label: `Le Groupe Vip`, value: `TUR` },
  { label: `Groupe Tva`, value: `TVA` },
  { label: `TAP Portugal`, value: `TP` },
  { label: `LA TOUR DES VOYAGEURS`, value: `TVO` },
  { label: `TVQ TPS`, value: `TVQ` },
  { label: `TRANSWORLD AIRLINES`, value: `TW-` },
  { label: `TRANSWORLD AIRLINES`, value: `TW-` },
  { label: `United Airlines`, value: `UA` },
  { label: `Unique Tours`, value: `UNI` },
  { label: `UNIKATOURS`, value: `UNIKAGABY` },
  { label: `Unique Vacations Inc.`, value: `UNIQUE` },
  { label: `Unisky Tours`, value: `UNIS` },
  { label: `Universal Studios`, value: `UNIVERSAL` },
  { label: `Uniword Boutique River Cruises`, value: `UNW` },
  { label: `Bahamas Air`, value: `UP` },
  { label: `Vacances Air Canada`, value: `VAC` },
  { label: `Vacances Sinorama`, value: `VACSIN` },
  { label: `Varaplaya Tours`, value: `VAP` },
  { label: `Vacances Transat`, value: `VAT` },
  { label: `Voyages Bernard Gendron Inc.`, value: `VBG` },
  { label: `Visa Connection`, value: `VC` },
  { label: `VACANCES CANJET`, value: `VCA` },
  { label: `Vacances Celibritours`, value: `VCE` },
  { label: `VACANCES CLUB GOLF`, value: `VCG` },
  { label: `Voyages Circuit Oriental Inc.`, value: `VCO` },
  { label: `Voyages Cassis`, value: `VCS` },
  { label: `Venere Hotels`, value: `VE` },
  { label: `Venere Hotel`, value: `VEN` },
  { label: `Tours Hai International`, value: `VHA` },
  { label: `VIA RAIL CANADA`, value: `VIA` },
  { label: `Via Rail Canada`, value: `VIAWEB` },
  { label: `VIACANADA`, value: `VIC` },
  { label: `Victours`, value: `VICTO` },
  { label: `VIKING CRUISE`, value: `VIK` },
  { label: `VISITE MAROC`, value: `VIM` },
  { label: `Visitours Inc.`, value: `VIS` },
  { label: `Visa Desjardins 9024`, value: `VISA 9024` },
  { label: `Viva Tours`, value: `VIV` },
  { label: `Vietnam Airlines`, value: `VN` },
  { label: `Voyages Fleur De Lys`, value: `VLL` },
  { label: `Carte Postale Tours`, value: `VLQ` },
  { label: `Assurances Rbc -NE PAS PRENDRE`, value: `VOY` },
  { label: `Vacances En Province`, value: `VPR` },
  { label: `Prendre Code Vap`, value: `VRP` },
  { label: `Voyages Sport Vac`, value: `VSV` },
  { label: `voyages tour étudiant`, value: `VTE` },
  { label: `Vacances Tours Mex`, value: `VTM` },
  { label: `VOYAGES YVON DUPUIS`, value: `VYD` },
  { label: `Voyages Gaby`, value: `WAG` },
  { label: `Voyages Gaby`, value: `WAGACC` },
  { label: `Ne Pas Prendre Ce Code`, value: `WAL` },
  { label: `Walt Disney Resort`, value: `WAT` },
  { label: `Week-end Express`, value: `WEEK` },
  { label: `WORLD EXPEDITIONS MONDE LTD`, value: `WEM` },
  { label: `West Jet Airlines Ltd.`, value: `WES` },
  { label: `Westjet Vacations`, value: `WESTVAC` },
  { label: `Windstar Cruises`, value: `WIN` },
  { label: `wingbuddy`, value: `WING` },
  { label: `Air Labrador`, value: `WJ` },
  { label: `SOUTHWEST AIRLINE`, value: `WN` },
  { label: `Wyder Tours`, value: `WYD` },
  { label: `AIR CREEBEC`, value: `YN` },
  { label: `Zip`, value: `ZIP` },
  { label: `Zoom Airlines`, value: `ZOM` }
]

export const SIGNATURE = {}